.c-btnTwitter {
  box-sizing: border-box;
  border: solid 1px #5c5c5c;
  display: block;
  height: 32px;
  width: 32px;

  @include u-max-default() {
    height: sizeVW-default(64);
    width: sizeVW-default(64);
  }

  &:before {
    background: url(../img/common/icon-twitter-g.svg) no-repeat center center;
    background-size: 16px 12px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;

    @include u-max-default() {
      background-size: sizeVW-default(29) sizeVW-default(24);
    }
  }
}

.c-btnTwitter--white {
  border: solid 1px #fff;

  &:before {
    background: url(../img/common/icon-twitter-w.svg) no-repeat center center;
    background-size: 16px 12px;

    @include u-max-default() {
      background-size: sizeVW-default(29) sizeVW-default(24);
    }
  }
}

.c-btnTwitter--black {
  border: solid 1px #727272;

  &:before {
    background: url(../img/common/icon-twitter.svg) no-repeat center center;
    background-size: 16px 12px;

    @include u-max-default() {
      background-size: sizeVW-default(29) sizeVW-default(24);
    }
  }
}

.c-btnTwitter--noborder {
  border: 0;
}