.c-newsList {
  display: flex;
  flex-wrap: wrap;

  @include u-max-default() {
    display: block;
  }
}

.c-newsList__set {
  margin-right: 2%;
  margin-bottom: 5.8%;
  width: 32%;

  @include u-max-default() {
    margin-right: 0;
    margin-bottom: sizeVW-default(54);
    width: 100%;
  }

  &:nth-child(3n+0) {
    margin-right: 0;
  }

  &:last-child {
    @include u-max-default() {
      margin-bottom: 0;
    }
  }

  a {
    display: block;
  }
}

.c-newsList__hover {
  display: block;
  position: relative;
  margin-bottom: 24px;
  backface-visibility: hidden;

  @include u-max-default() {
    margin-bottom: sizeVW-default(40);
  }

  &:before {
    content: '';
    display: block;
    padding-top: (270 / 480) * 100%;
  }
}

.c-newsList__hover__wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  transform: scale(1) perspective(0);
  transform-origin: 50% 50%;
}

.c-newsList__hover__photo {
  backface-visibility: hidden;
  transform: scale(1) perspective(0);
  transform-origin: 50% 50%;

  > img {
    backface-visibility: hidden;
    display: block;
    height: 100%;
    width: 100%;
  }
}

.c-newsList__info {
  > h2 {
    @include u-font-size(16);
    font-weight: 700;
    line-height: (28 / 16);
    margin-bottom: 8px;

    @include u-max-default() {
      font-size: sizeVW-default(26);
      line-height: (40 / 26);
      margin-bottom: sizeVW-default(18);
    }
  }

  > h3 {
    @include u-font-size(12);
    line-height: (22 / 12);

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (30 / 22);
    }
  }
}

.c-newsList__info__wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(24);
  }

  li:nth-child(2) {
    margin-left: 10px;

    p {
      @include u-font-size(12);
      font-weight: 700;
      letter-spacing: 0.04em;
    }
  }
}