.c-test {
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 2%;
      margin-bottom: 3.8%;
      width: 32%;

      &:nth-child(3n+0) {
        margin-right: 0;
      }
    }
  }
}

.c-hover {
  position: relative;
  cursor: pointer;
  //height: 270px;
  //width: 480px;
  backface-visibility: hidden;
  transform: scale(1) perspective(0);
  transform-origin: 50% 50%;

  &:before {
    content: '';
    display: block;
    padding-top: (270 / 480) * 100%;
  }

  &:hover {
    .c-skew {
     // transform: skew(-10deg, -10deg) scale(0.8) perspective(0);

      .c-skew__wrap {
        //transform: skew(10deg, 10deg) scale(1.5) perspective(0);
      }
    }
  }
}

.c-skew {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  //transition: all 0.4s;
  transform: scale(1) perspective(0);
  transform-origin: 50% 50%;
}

.c-skew__wrap {
  //transition: all 0.4s;
  transform: scale(1) perspective(0);
  transform-origin: 50% 50%;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}