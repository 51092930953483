.c-footer {

}

.c-footer--top {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.c-footerTop {
  position: relative;
}

.c-footerTop__cr {
  @include u-font-size(14);
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.14em;
  position: absolute;
  bottom: 30px;
  left: 30px;

  @include u-max-default() {

  }
}

.c-footerTop__links {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 30px;
  right: 30px;

  li {
    display: flex;
    align-items: center;

    a {
      @include u-font-size(14);
      color: #fff;
      font-weight: 700;
      letter-spacing: 0.14em;
    }
  }

  li + li {
    margin-left: 13px;

    &:before {
      background-color: rgba(255, 255, 255, 0.6);
      content: '';
      display: block;
      height: 12px;
      width: 1px;
      margin-right: 13px;
    }
  }
}

.c-footerTop__links__access {
  display: flex;
  align-items: center;

  svg {
    fill-rule: evenodd;
    fill: rgb(255, 255, 255);
    height: 13px;
    width: 10px;
    margin-right: 4px;
  }
}

.c-footerCmn {
  background-color: #000;
  color: #b8b8b8;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  display: flex;
  justify-content: space-between;

  @include u-max-default() {
    display: block;
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    padding-top: sizeVW-default(100);
    padding-bottom: sizeVW-default(100);
  }

  a {
    color: #b8b8b8;
  }
}

.c-footerCmn__wrap1 {
  box-sizing: border-box;
  padding-bottom: 90px;

  @include u-max-default() {
    padding-bottom: sizeVW-default(72);
  }
}

.c-footerCmn__wrap1Logo {
  height: 40px;
  width: 184px;

  @include u-max-default() {
    height: sizeVW-default(60);
    width: sizeVW-default(276);
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.c-footerCmn__wrap1Address {
  @include u-font-size(12);
  font-weight: 700;
  letter-spacing: 0.14em;
  line-height: (22 / 12);
  margin-top: 26px;

  @include u-max-default() {
    font-size: sizeVW-default(22);
    line-height: (40 / 22);
    margin-top: sizeVW-default(52);
  }
}

.c-footerCmn__wrap2 {
  box-sizing: border-box;

  @include u-max-default() {
    border-top: solid 1px #383838;
    padding-top: sizeVW-default(74);
    padding-bottom: sizeVW-default(50);
  }
}

.c-footerCmn__wrap2Pages {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: -1px;
  margin-top: -2px;

  @include u-max-default() {
    margin-right: 0;
    margin-top: 0;
    justify-content: flex-start;
  }

  li {
    margin-right: 28px;
    margin-bottom: 10px;

    @include u-max-default() {
      margin-right: sizeVW-default(46);
      margin-bottom: sizeVW-default(30);
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      @include u-font-size(18);
      font-weight: 700;
      letter-spacing: 0.14em;

      @include u-max-default() {
        font-size: sizeVW-default(34);
      }
    }
  }

  li + li {

  }
}

.c-footerCmn__wrap3 {
  box-sizing: border-box;
  text-align: right;
  position: absolute;
  bottom: 80px;
  right: 80px;

  @include u-max-default() {
    border-top: solid 1px #383838;
    padding-top: sizeVW-default(80);
    text-align: left;
    position: relative;
    bottom: auto;
    right: auto;
  }
}

.c-footerCmn__wrap3Privacy {
  @include u-font-size(12);
  font-weight: 700;
  letter-spacing: 0.14em;
  margin-right: -1px;

  @include u-max-default() {
    font-size: sizeVW-default(22);
    margin-right: 0;
  }
}

.c-footerCmn__wrap3Sns {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @include u-max-default() {
    justify-content: flex-start;
    margin-top: sizeVW-default(24);
  }

  li + li {
    margin-left: 10px;

    @include u-max-default() {
      margin-left: sizeVW-default(20);
    }
  }
}

.c-footerCmn__cr {
  @include u-font-size(12);
  font-weight: 700;
  letter-spacing: 0.14em;
  position: absolute;
  bottom: 80px;
  left: 79px;

  @include u-max-default() {
    font-size: sizeVW-default(22);
    position: relative;
    bottom: auto;
    left: auto;
    margin-top: sizeVW-default(59);
  }
}