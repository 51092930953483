.p-newsEntry {
  margin-bottom: 180px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(190);
  }
}

.p-newsEntry__mv {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: (745 / 1680) * 100%;

    @include u-max-default() {
      padding-top: (856 / 750) * 100%;
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.p-newsEntry__con {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    margin-bottom: sizeVW-default(120);
  }
}

.p-newsEntry__conTitle {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding-top: 72px;
  padding-bottom: 78px;
  margin-bottom: 74px;
  text-align: center;

  @include u-max-default() {
    padding-top: sizeVW-default(48);
    padding-bottom: sizeVW-default(60);
    margin-bottom: sizeVW-default(50);
    text-align: left;
  }

  > h1 {
    @include u-font-size(38);
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: (52 / 38);

    @include u-max-default() {
      font-size: sizeVW-default(38);
      line-height: (54 / 36);
    }
  }

  > h2 {
    @include u-font-size(16);
    letter-spacing: 0.14em;
    line-height: 1;
    margin-top: 26px;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      letter-spacing: 0.06em;
      margin-top: sizeVW-default(16);
    }
  }
}

.p-newsEntry__conSummary {
  margin-bottom: 50px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(64);
  }

  > p {
    @include u-font-size(14);
    line-height: (38 / 14);
    letter-spacing: 0.14em;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
    }
  }

  > p + p {
    margin-top: 3em;
  }
}

.p-newsEntry__conGallery {

}

.p-newsEntry__conGallery__photo {

  & + .p-newsEntry__conGallery__photo,
  & + .p-newsEntry__conGallery__video {
    margin-top: 60px;

    @include u-max-default() {
      margin-top: sizeVW-default(60);
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.p-newsEntry__conGallery__video {
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: (9 / 16) * 100%;
  }

  & + .p-newsEntry__conGallery__photo,
  & + .p-newsEntry__conGallery__video {
    margin-top: 60px;

    @include u-max-default() {
      margin-top: sizeVW-default(60);
    }
  }

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

/*
.p-newsEntry__conGallery {
  box-sizing: border-box;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding-bottom: 80px;

  @include u-max-default() {
    padding-bottom: 96px;
  }
}

.p-newsEntry__conGallery__photo {
  height: 100%;
  width: 100%;

  > img {
    display: block;
    height: auto;
    width: 100%;
  }
}


.p-newsEntry__conGallery__video {
  height: 100%;
  width: 100%;

  > video {
    display: block;
    height: auto;
    width: 100%;
  }
}
*/

.p-newsEntry__other {
  box-sizing: border-box;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}