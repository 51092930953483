.c-dotTitle {
  @include u-font-size(58);
  @include f-ITC-BoldIt();
  letter-spacing: 0.04em;
  display: flex;
  align-items: flex-end;

  @include u-max-default() {
    font-size: sizeVW-default(58);
    letter-spacing: 0.08em;
  }
}

.c-dotTitle__dot {
  background: url(../img/common/title-dot.svg) no-repeat left top;
  background-size: 100% 100%;
  content: '';
  display: block;
  height: 8px;
  width: 9px;

  @include u-max-default() {
    height: sizeVW-default(12);
    width: sizeVW-default(13);
  }

  & + .c-dotTitle__dot {
    margin-left: 2px;

    @include u-max-default() {
      margin-left: sizeVW-default(2);
    }
  }
}

.c-dotTitle__text {
  display: block;
  margin-top: -12px;
  margin-bottom: -9px;
  margin-left: 12px;

  @include u-max-default() {
    margin-top: sizeVW-default(-11);
    margin-bottom: sizeVW-default(-8);
    margin-left: sizeVW-default(12);
  }
}