.p-dept {
  margin-bottom: 180px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(190);
  }
}

.p-dept__mv {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding-left: 90px;
  padding-right: 90px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include u-max-default() {
    padding-left: 0;
    padding-right: 0;
  }
}

.p-dept__mvBg {
  background-color: #f00;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.p-dept__mvWrap {
  position: relative;
  box-sizing: border-box;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-dept__mvWrap__logo {

  .p-dept--tech & {

    img {
      height: 200px;
      width: 502px;

      @include u-max-default() {
        height: sizeVW-default(175);
        width: sizeVW-default(439);
      }
    }
  }

  .p-dept--live & {

    img {
      height: 200px;
      width: 499px;

      @include u-max-default() {
        height: sizeVW-default(175);
        width: sizeVW-default(437);
      }
    }
  }

  .p-dept--original & {
    img {
      height: 200px;
      width: 617px;

      @include u-max-default() {
        height: sizeVW-default(175);
        width: sizeVW-default(539);
      }
    }
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    @include u-font-size(16);
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.14em;
    text-align: center;
    margin-top: 56px;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (40 / 24);
      margin-top: sizeVW-default(48);
    }
  }
}

.p-dept__mvWrap__scroll {
  background-color: #000;
  color: #fff;
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;

  @include u-max-default() {
    height: sizeVW-default(120);
  }

  span {
    @include f-ITC-BoldIt();
    @include u-font-size(16);
    letter-spacing: 0.1em;
    padding-bottom: 10px;
    background: linear-gradient(#fff, #fff) 0 100%/0 2px no-repeat;
    background-size: 100% 2px;
    text-decoration: none;

    @include u-max-default() {
      font-size: sizeVW-default(26);
      background: linear-gradient(#fff, #fff) 0 100%/0 sizeVW-default(2) no-repeat;
      background-size: 100% sizeVW-default(2);
      padding-bottom: sizeVW-default(10);
    }
  }
}

.p-dept__intro {
  box-sizing: border-box;
  max-width: 1680px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;

  @include u-max-default() {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: sizeVW-default(120);
  }
}

.p-dept__introWrap {
  background-color: #fff;
  display: flex;
  align-items: center;

  @include u-max-default() {
    display: block;
    padding-top: sizeVW-default(100);
    padding-bottom: sizeVW-default(100);
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}

.p-dept__introWrap__logo {
  height: 100%;
  flex-basis: (524 / 1500) * 100%;
  min-width: (524 / 1500) * 100%;
  width: (524 / 1500) * 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;

  @include u-max-default() {
    height: auto;
    min-width: 100%;
    width: 100%;
    padding-right: 0;
    margin-bottom: sizeVW-default(66);
  }

  img {
    display: block;

    .p-dept--original & {
      height: auto;
      width: 308px;

      @include u-max-default() {
        width: sizeVW-default(308);
      }
    }

    .p-dept--live & {
      height: auto;
      width: 249px;

      @include u-max-default() {
        width: sizeVW-default(249);
      }
    }
  }
}

.p-dept__introWrap__info {
  box-sizing: border-box;
  padding-top: 68px;
  padding-bottom: 70px;
  padding-right: 100px;
  width: 100%;

  @include u-max-default() {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  h3 {
    @include u-font-size(26);
    line-height: (42 / 26);
    letter-spacing: 0.1em;
    margin-bottom: 4px;

    @include u-max-default() {
      font-size: sizeVW-default(38);
      line-height: (52 / 38);
      letter-spacing: 0.04em;
      margin-bottom: sizeVW-default(18);
    }
  }

  h2 {
    @include u-font-size(34);
    font-weight: 700;
    line-height: (65 / 34);
    letter-spacing: 0.06em;

    @include u-max-default() {
      font-size: sizeVW-default(36);
      line-height: (56 / 36);
      letter-spacing: 0.1em;
    }
  }

  p {
    @include u-font-size(14);
    line-height: (38 / 14);
    letter-spacing: 0.12em;
    margin-top: 20px;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
      letter-spacing: 0.14em;
      margin-top: sizeVW-default(24);
    }
  }
}

.p-dept__case {
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }

  & + .p-dept__case {
    margin-top: 80px;

    @include u-max-default() {
      margin-top: sizeVW-default(60);
    }
  }
}

.p-dept__casePhoto {
  width: 100%;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.p-dept__caseInfo {
  box-sizing: border-box;
  background-color: #fff;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 58px;
  padding-bottom: 54px;
  margin-left: auto;
  margin-top: -100px;
  width: (1000 / 1200) * 100%;
  position: relative;

  @include u-max-default() {
    padding-left: sizeVW-default(40);
    padding-right: sizeVW-default(40);
    padding-top: sizeVW-default(50);
    padding-bottom: sizeVW-default(54);
    margin-top: 0;
    width: 100%;
  }

  .p-dept__case:nth-child(even) & {
    margin-left: 0;
  }

  h2 {
    @include u-font-size(16);
    font-weight: 700;
    line-height: (26 / 16);

    @include u-max-default() {
      font-size: sizeVW-default(26);
      line-height: (38 / 26);
    }
  }

  h3 {
    @include u-font-size(12);
    margin-top: 14px;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (38 / 26);
    }
  }

  p {
    @include u-font-size(12);
    line-height: (29 / 12);
    position: relative;
    margin-top: 40px;
    padding-top: 33px;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (42 / 24);
      letter-spacing: 0.14em;
    }

    &:before {
      background-color: rgba(0, 0, 0, 1.0);
      content: '';
      display: block;
      height: 1px;
      width: 60px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.p-dept__caseInfo__num {
  @include u-font-size(16);
  font-weight: 700;
  font-style: italic;
  margin-bottom: 5px;
}

.p-dept__border {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:after {
    background-color: rgba(0, 0, 0, 0.8);
    content: '';
    display: block;
    height: 1px;
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;

    @include u-max-default() {
      width: sizeVW-default(140);
    }
  }
}

.p-dept__con {
  box-sizing: border-box;
  max-width: 1500px;
  padding-left: 150px;
  padding-right: 150px;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}

.p-dept__conCarousel {
  position: relative;
}

.p-dept__conWorks {
  position: relative;
  padding-top: 80px;
  margin-top: 80px;

  @include u-max-default() {
    padding-top: sizeVW-default(58);
    margin-top: sizeVW-default(100);
  }
}

.p-dept__conWorks__title {
  @include f-ITC-BoldIt();
  @include u-font-size(18);
  letter-spacing: 0.04em;
  margin-bottom: 9px;
  display: flex;
  align-items: center;

  @include u-max-default() {
    font-size: sizeVW-default(28);
    letter-spacing: 0.04em;
    margin-bottom: sizeVW-default(20);
  }

  &:before {
    background-color: #8c8c8c;
    content: '';
    display: block;
    height: 1px;
    width: 10px;
    margin-right: 4px;

    @include u-max-default() {
      height: sizeVW-default(2);
      width: sizeVW-default(20);
      margin-right: sizeVW-default(8);
    }
  }
}

.p-dept__conWorks__list {
  li {
    @include u-font-size(14);
    font-weight: bold;
    line-height: (34 / 14);
    letter-spacing: 0.06em;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (38 / 22);
    }

    span {
      font-weight: 400;
    }
  }
}

.p-dept__member {
  box-sizing: border-box;
  max-width: 1680px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;

  @include u-max-default() {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: sizeVW-default(120);
  }
}

.p-dept__member__title {
  @include u-font-size(38);
  letter-spacing: 0.04em;
  margin-bottom: 42px;

  @include u-max-default() {
    font-size: sizeVW-default(58);
    margin-bottom: sizeVW-default(42);
  }
}

.p-dept__case {
  box-sizing: border-box;
  max-width: 1680px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;

  @include u-max-default() {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: sizeVW-default(120);
  }
}

.p-dept__other {
  box-sizing: border-box;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 130px;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    margin-top: sizeVW-default(110);
  }
}
