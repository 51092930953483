.c-swipeSlide {

  @include u-max-default() {
    margin-bottom: sizeVW-default(50);
  }

  .swiper-wrapper {
    display: flex;

    .swiper-slide {
      height: 480px;
      width: 840px;

      @include u-max-default() {
        height: sizeVW-default(337);
        width: sizeVW-default(590);
      }

      img {
        @include u-max-default() {
          display: block;
          height: auto;
          max-width: 100%;
        }
      }
    }
  }
}

.c-swipeSlide__ui {
  margin-top: 50px;
}

.swiper-pagination {

}

.swiper-pagination-bullets {
  display: flex;
  justify-content: center;

  .swiper-pagination-bullet {
    background-color: #d2d5de;
    margin-right: 20px;
    height: 2px;
    width: 40px;

    @include u-max-default() {
      margin-right: sizeVW-default(20);
      height: sizeVW-default(4);
      width: sizeVW-default(40);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $color-blue;
  }

  /*
  ul {
    display: flex;
    justify-content: center;

    li {
      background-color: #d2d5de;
      margin-right: 20px;
      height: 2px;
      width: 40px;

      @include u-max-default() {
        margin-right: sizeVW-default(20);
        height: sizeVW-default(4);
        width: sizeVW-default(40);
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }*/

}