.c-btnSquare {
  @include u-font-size(14);
  background-color: #000;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.1em;
  height: 80px;
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include u-max-default() {
    font-size: sizeVW-default(24);
    height: sizeVW-default(100);
    width: sizeVW-default(360);
  }
}

.c-btnSquare--attached {

  &:after {
    background: url(../img/common/icon-attached.svg) no-repeat left top;
    background-size: 100% 100%;
    content: '';
    display: block;
    height: 14px;
    width: 15px;
    margin-left: 8px;

    @include u-max-default() {
      height: sizeVW-default(24);
      width: sizeVW-default(25);
      margin-left: sizeVW-default(10);
    }
  }

  input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
}