.p-news {
  box-sizing: border-box;
  padding-left: 90px;
  padding-right: 90px;
  margin-bottom: 160px;
  width: 100%;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    margin-bottom: sizeVW-default(180);
  }
}

.p-newsHead {
  padding-top: 120px;
  margin-bottom: 102px;

  @include u-max-default() {
    padding-top: sizeVW-default(180);
    margin-bottom: sizeVW-default(80);
  }
}

.p-newsHead__title {
  margin-bottom: 40px;
  text-align: center;

  @include u-max-default() {
    margin-bottom: sizeVW-default(59);
  }
}

.p-newsHead__tool {

}

.p-newsHead__toolCate {
  display: flex;
  align-items: flex-start;
  position: relative;

  @include u-max-default() {
    border-top: solid sizeVW-default(2) #000;
    border-bottom: solid sizeVW-default(2) #000;
    padding-top: sizeVW-default(28);
    padding-bottom: sizeVW-default(28);
    width: sizeVW-default(430);
  }

  > h2 {
    @include u-font-size(20);
    font-weight: 700;
    letter-spacing: 0.14em;
    display: flex;
    align-items: center;

    @include u-max-default() {
      font-size: sizeVW-default(30);
    }

    &:after {
      content: ':';
      display: block;
      margin-top: -5px;
      margin-left: 6px;
      margin-right: 6px;

      @include u-max-default() {
        margin-top: sizeVW-default(-6);
        margin-left: sizeVW-default(10);
        margin-right: sizeVW-default(6);
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;

    @include u-max-default() {
      display: none;
    }

    li {
      @include u-font-size(20);
      font-weight: 700;
      letter-spacing: 0.14em;
      display: flex;
      align-items: center;

      a {
        position: relative;

        &:after {
          background-color: #000;
          content: '';
          display: block;
          height: 2px;
          width: 100%;
          margin-top: 3px;
          margin-left: -3px;
          visibility: hidden;
        }

        &.isSelected {
          &:after {
            visibility: visible;
          }
        }
      }
    }

    li + li {
      &:before {
        content: '/';
        display: block;
        margin-left: 8px;
        margin-right: 9px;
        margin-top: -5px;
      }
    }
  }
}

.p-newsHead__toolCate__current {
  display: none;

  @include u-max-default() {
    font-size: sizeVW-default(30);
    display: block;
    letter-spacing: 0.14em;
  }
}

.p-newsHead__toolCate__arrow {
  display: none;

  @include u-max-default() {
    display: block;
    position: absolute;
    top: sizeVW-default(16);
    right: sizeVW-default(8);
    height: sizeVW-default(28);
    width: sizeVW-default(28);

    &:before {
      border-bottom: solid sizeVW-default(2) #000;
      border-left: solid sizeVW-default(2) #000;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: sizeVW-default(28);
      width: sizeVW-default(28);
      transform-origin: 50% 50%;
      transform: rotate(-45deg);
    }
  }
}

.p-newsHead__toolTag {
  @include u-font-size(20);
  font-weight: 700;
  letter-spacing: 0.14em;
  display: flex;
  align-items: center;

  @include u-max-default() {
    border-top: solid sizeVW-default(2) #000;
    border-bottom: solid sizeVW-default(2) #000;
    padding-top: sizeVW-default(28);
    padding-bottom: sizeVW-default(28);
    font-size: sizeVW-default(30);
    width: sizeVW-default(170);
    justify-content: center;
  }

  &:before {
    background-color: #000;
    content: '';
    display: block;
    height: 1px;
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;

    @include u-max-default() {
      margin-left: sizeVW-default(4);
      margin-right: sizeVW-default(10);
      height: sizeVW-default(2);
      width: sizeVW-default(40);
    }
  }
}

.p-newsList {
  @include u-max-default() {
    margin-bottom: sizeVW-default(116);
  }
}

.p-newsPager {
  display: flex;
  justify-content: center;
  align-items: center;

  .navigation {
    width: 100%;
  }

  .pagination {

  }

  .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;

    @include u-max-default() {
      justify-content: flex-start;
    }
  }

  .page-numbers {
    @include u-font-size(24);
    box-sizing: border-box;
    background-color: #000;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.12em;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include u-max-default() {
      display: none;
      font-size: sizeVW-default(28);
      letter-spacing: 0.14em;
    }

    &:hover {
      background-color: #fff;
      color: #000;
    }

    &.current {
      background-color: #fff;
      color: #000;
    }

    &.prev {
      @include u-text-replace();

      @include u-max-default() {
        text-indent: 0;
        height: sizeVW-default(90);
        width: sizeVW-default(300);
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
      }

      &:after {
        box-sizing: border-box;
        border-left: solid 1px #fff;
        border-bottom: solid 1px #fff;
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        transform: rotate(45deg);
        transform-origin: 0% 100%;
        position: absolute;
        top: 25px;
        left: 34px;

        @include u-max-default() {
          background-color: #fff;
          border: 0;
          height: sizeVW-default(2);
          width: sizeVW-default(60);
          transform: none;
          position: static;
          margin-right: sizeVW-default(32);
        }
      }

      &:hover {
        &:after {
          border-left: solid 1px #000;
          border-bottom: solid 1px #000;
        }
      }
    }

    &.next {
      @include u-text-replace();

      @include u-max-default() {
        text-indent: 0;
        height: sizeVW-default(90);
        width: sizeVW-default(300);
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:after {
        box-sizing: border-box;
        border-right: solid 1px #fff;
        border-bottom: solid 1px #fff;
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        transform: rotate(-45deg);
        transform-origin: 100% 100%;
        position: absolute;
        top: 25px;
        right: 34px;

        @include u-max-default() {
          background-color: #fff;
          border: 0;
          height: sizeVW-default(2);
          width: sizeVW-default(60);
          transform: none;
          position: static;
          margin-left: sizeVW-default(32);
        }
      }

      &:hover {
        &:after {
          border-right: solid 1px #000;
          border-bottom: solid 1px #000;
        }
      }
    }

    &.dots {

    }
  }
}
