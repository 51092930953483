.c-related {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 658px;
  width: 100%;
  margin-bottom: 120px;

  @include u-max-default() {
    height: sizeVW-default(1000);
    margin-bottom: 0;
  }
}

.c-related__title {
  padding-top: 120px;

  @include u-max-default() {
    padding-top: sizeVW-default(140);
    text-align: center;
  }

  > h1 {
    > img {
      display: block;
      height: 45px;
      width: 211px;

      @include u-max-default() {
        height: sizeVW-default(53);
        width: sizeVW-default(248);
        margin-left: auto;
        margin-right: auto;
      }
    }

    > span {
      @include u-font-size(72);
      font-weight: 700;
      display: block;
      letter-spacing: 0.04em;
      margin-top: 4px;

      @include u-max-default() {
        font-size: sizeVW-default(85);
        margin-top: sizeVW-default(2);
      }
    }
  }

  > p {
    @include u-font-size(16);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: (30 / 16);
    margin-top: 30px;

    @include u-max-default() {
      font-size: sizeVW-default(26);
      line-height: (40 / 26);
      margin-top: sizeVW-default(36);
    }

    &:before {
      background-color: #000;
      content: '';
      display: block;
      height: 2px;
      width: 40px;
      margin-bottom: 30px;

      @include u-max-default() {
        height: sizeVW-default(4);
        width: sizeVW-default(60);
        margin-bottom: sizeVW-default(40);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.c-relatedCircle__lines {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.c-relatedCircle__linesChild {
  display: block;
  height: 1px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
  overflow: hidden;

  > div {
    background-color: rgba(207, 207, 207, 1.0);
    height: 1px;
    width: 100%;
  }
}

.c-relatedCircle {
  box-sizing: border-box;
  height: 240px;
  width: 240px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 50% 50%;

  &--parent {
    pointer-events: none;
  }

  &--child {
    cursor: pointer;
  }
}

.c-relatedCircle__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform-origin: 50% 50%;
  }
}

.c-relatedCircle__bgHover {
  circle {
    fill-rule: evenodd;
    fill: none;
    fill-opacity: 0;
    stroke-width: 2px;
    stroke: rgb(255, 255, 255);
    opacity: 1;
  }
}

.c-relatedCircle__bgMain {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;

  circle {
    fill-rule: evenodd;
    fill: none;
    fill-opacity: 0;
    stroke-width: 2px;
    stroke: rgba(255, 255, 255, 0.4);
  }
}

.c-relatedCircle__photo {
  box-sizing: border-box;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    padding-top: 50%;
  }

  > img {
    display: block;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.c-relatedCircle__text {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  > p {
    @include u-font-size(18);
    color: #fff;
    font-weight: 700;
    line-height: (20 / 18);
    letter-spacing: 0.1em;
    position: relative;
  }
}