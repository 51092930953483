.c-bnrContact {
  box-sizing: border-box;
  background-color: #fff;
  height: 240px;
  width: 100%;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include u-max-default() {
    height: sizeVW-default(220);
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(64);
  }
}

.c-bnrContact__text {
  span {
    &:nth-child(1) {
      @include u-font-size(62);
      font-weight: 700;
      letter-spacing: 0.1em;

      @include u-max-default() {
        font-size: sizeVW-default(62);
        display: block;
      }
    }
    &:nth-child(2) {
      @include u-font-size(14);
      letter-spacing: 0.14em;
      margin-left: 10px;

      @include u-max-default() {
        font-size: sizeVW-default(22);
        display: block;
        margin-left: 0;
        margin-top: sizeVW-default(20);
      }
    }
  }
}

.c-bnrContact__arrow {
  display: block;
  height: 66px;
  width: 66px;
  position: relative;

  @include u-max-default() {
    height: sizeVW-default(66);
    width: sizeVW-default(66);
  }

  &:after {
    box-sizing: border-box;
    border-right: solid 2px #000;
    border-bottom: solid 2px #000;
    content: '';
    display: block;
    height: 66px;
    width: 66px;
    transform: rotate(-45deg);
    transform-origin: 50% 50%;

    @include u-max-default() {
      border-right: solid sizeVW-default(2) #000;
      border-bottom: solid sizeVW-default(2) #000;
      height: sizeVW-default(66);
      width: sizeVW-default(66);
    }
  }
}