.p-picsWrapperBg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &.isBg1 {
    background: url(../img/dummy/bg1_pc.jpg) no-repeat center center;
    background-size: cover;

    @include u-max-default() {
      background: url(../img/dummy/bg1_sp.jpg) no-repeat center center;
      background-size: cover;
    }
  }

  &.isBg2 {
    background: url(../img/dummy/bg2_pc.jpg) no-repeat center center;
    background-size: cover;

    @include u-max-default() {
      background: url(../img/dummy/bg2_sp.jpg) no-repeat center center;
      background-size: cover;
    }
  }
}

.p-picsWrapper {
  position: relative;
  width: 100%;
}

.p-picsWrapper img {
  display: block;
  height: auto;
  width: 100%;
}