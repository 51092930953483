/*
 * メディアクエリの数値を変更する場合は
 * jsのmatchMediaも修正が必要
 */
$breakpoint-iphone5: 320;
$breakpoint-ipad: 768;
$breakpoint-design-sp: 750;
$breakpoint-design: 900;
$breakpoint-tablet: 1280;
$breakpoint: $breakpoint-design;

$color-blue: #1f2c5c;