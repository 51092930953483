.c-toggle {
  background-color: #000;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include u-max-default() {
    height: sizeVW-default(90);
    width: sizeVW-default(90);
  }
}

.c-toggle__cross {
  height: 25px;
  width: 25px;
  position: relative;

  @include u-max-default() {
    height: sizeVW-default(42);
    width: sizeVW-default(42);
  }

  &:before {
    background-color: #fff;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 12px;
    left: 0;

    @include u-max-default() {
      height: sizeVW-default(2);
      top: sizeVW-default(20);
    }
  }

  &:after {
    background-color: #fff;
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    left: 12px;

    @include u-max-default() {
      width: sizeVW-default(2);
      left: sizeVW-default(20);
    }
  }

  .c-toggle--close & {
    &:after {
      visibility: hidden;
    }
  }
}