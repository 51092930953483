.c-headTitle {
  @include f-ITC-BoldIt();
  @include u-font-size(108);
  letter-spacing: 0.1em;
  margin-top: -23px;
  margin-bottom: -16px;
  margin-left: -5px;
  margin-right: -10px;
  text-align: center;

  @include u-max-default() {
    font-size: sizeVW-default(116);
    letter-spacing: 0.12em;
    margin-top: sizeVW-default(-24);
    margin-bottom: sizeVW-default(-16);
    margin-left: sizeVW-default(-5);
    margin-right: sizeVW-default(-14);
  }
}

.c-headTitle--w {
  color: #fff;
}