.c-carousel {
  /*box-sizing: border-box;
  border-bottom: solid 1px #d0d0d0;
  margin-bottom: 120px;

  @include u-max-default() {
    padding-bottom: 96px;
    margin-bottom: 0;
  }*/
}

.c-carousel__info {
  margin-top: 30px;

  @include u-max-default() {
    margin-top: sizeVW-default(30);
  }

  p {
    @include u-font-size(14);
    line-height: (28 / 14);
    letter-spacing: 0.06em;
    text-align: center;

    @include u-max-default() {
      font-size: sizeVW-default(26);
      line-height: (43 / 26);
      letter-spacing: 0.06em;
    }
  }
}

.c-carousel__seek {
  background-color: #bababa;
  margin-left: auto;
  margin-right: auto;
  margin-top: 34px;
  height: 2px;
  width: 180px;

  @include u-max-default() {
    margin-top: sizeVW-default(32);
    height: sizeVW-default(4);
    width: sizeVW-default(150);
  }
}

.c-carousel__slide {
  position: relative;
  width: 100%;

  @include u-max-default() {

  }

  &:before {
    content: '';
    display: block;
    padding-top: (675 / 1200) * 100%;
  }
}

.c-carousel__slidePhoto {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  > img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.c-carousel__slidePrev, .c-carousel__slideNext {
  background-color: #000;
  cursor: pointer;
  height: 80px;
  width: 80px;
  position: absolute;
  top: 50%;
  margin-top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include u-max-default() {
    height: sizeVW-default(90);
    width: sizeVW-default(90);
    margin-top: sizeVW-default(-45);
  }
}

.c-carousel__slidePrev {
  left: -40px;

  @include u-max-default() {
    left: sizeVW-default(-40);
  }

  > span {
    display: block;
    height: 16px;
    width: 16px;
    margin-left: 8px;

    @include u-max-default() {
      height: sizeVW-default(20);
      width: sizeVW-default(20);
      margin-left: sizeVW-default(10);
    }

    &:after {
      box-sizing: border-box;
      border-left: solid 1px #e8e8e8;
      border-bottom: solid 1px #e8e8e8;
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      transform: rotate(45deg);
      transform-origin: 50% 50%;

      @include u-max-default() {
        height: sizeVW-default(20);
        width: sizeVW-default(20);
      }
    }
  }
}

.c-carousel__slideNext {
  right: -40px;

  @include u-max-default() {
    right: sizeVW-default(-40);
  }

  > span {
    display: block;
    height: 16px;
    width: 16px;
    margin-right: 8px;

    @include u-max-default() {
      height: sizeVW-default(20);
      width: sizeVW-default(20);
      margin-right: sizeVW-default(10);
    }

    &:after {
      box-sizing: border-box;
      border-right: solid 1px #e8e8e8;
      border-bottom: solid 1px #e8e8e8;
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      transform: rotate(-45deg);
      transform-origin: 50% 50%;

      @include u-max-default() {
        height: sizeVW-default(20);
        width: sizeVW-default(20);
      }
    }
  }
}