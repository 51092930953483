.c-cover {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100000;
}

.c-cover__a-enter-active {
  transition: transform 0.4s $_3_CubicIn 0.1s;
  will-change: transform;
}

.c-cover__a-enter {
  transform: translate3d(-100%, 0, 0);
}

.c-cover__a-enter-to {
  transform: translate3d(0%, 0, 0);
}

.c-cover__a-leave-active {
  transition: transform 0.6s $_34_CubicQuartInOut;
  will-change: transform;
}

.c-cover__a-leave {
  transform: translate3d(0%, 0, 0);
}

.c-cover__a-leave-to {
  transform: translate3d(100%, 0, 0);
}

.c-cover__a {
  background-color: rgba(255, 255, 255, 1.0);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
}

.c-cover__b-enter-active {
  transition: transform 0.4s $_3_CubicIn;
  will-change: transform;
}

.c-cover__b-enter {
  transform: translate3d(-100%, 0, 0);
}

.c-cover__b-enter-to {
  transform: translate3d(0%, 0, 0);
}

.c-cover__b-leave-active {
  transition: transform 0.6s $_34_CubicQuartInOut 0.1s;
  will-change: transform;
}

.c-cover__b-leave {
  transform: translate3d(0%, 0, 0);
}

.c-cover__b-leave-to {
  transform: translate3d(100%, 0, 0);
}

.c-cover__b {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
}