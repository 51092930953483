.c-wrapper {
  width: 100%;
}

.c-wrapper__inner {

  &.isActive {
    transform: translate3d(-30%, 0, 0);
    transition: none;
    visibility: hidden;
  }

  &.isHide {
    transform: translate3d(0%, 0, 0);
    transition: transform 0.7s $_3_CubicOut 0.2s;
    visibility: visible;
  }

  &.isShow {
    transform: translate3d(30%, 0, 0);
    transition: transform 0.6s $_3_CubicIn 0s;
    visibility: visible;
  }
}