.c-btnPlay {
  background: url(../img/common/icon-play.svg) no-repeat center center;
  background-size: 100% 100%;
  display: block;
  height: 120px;
  width: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -60px;
  margin-left: -60px;

  @include u-max-default() {
    height: sizeVW-default(120);
    width: sizeVW-default(120);
    margin-top: sizeVW-default(-60);
    margin-left: sizeVW-default(-60);
  }
}