.c-btnBack {
  box-sizing: border-box;
  border-radius: 0;
  border: 0;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  height: 130px;
  width: 100%;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;

  @include u-max-default() {
    height: sizeVW-default(250);
  }
}

.c-btnBack__text {
  padding-left: 50px;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    display: flex;
    flex-direction: column;
  }
}

.c-btnBack__textEn {
  @include u-font-size(42);
  @include f-ITC-BoldIt();
  letter-spacing: 0.08em;

  @include u-max-default() {
    font-size: sizeVW-default(62);
    letter-spacing: 0.06em;
    margin-top: sizeVW-default(-12);
  }
}

.c-btnBack__textJa {
  @include u-font-size(14);
  letter-spacing: 0.14em;
  margin-left: 6px;

  @include u-max-default() {
    font-size: sizeVW-default(22);
    margin-left: sizeVW-default(-10);
  }
}

.c-btnBack__arrow {
  display: block;
  height: 16px;
  width: 16px;
  position: absolute;
  right: 46px;
  top: 50%;
  margin-top: -8px;

  @include u-max-default() {
    height: sizeVW-default(64);
    width: sizeVW-default(64);
    margin-top: sizeVW-default(-32);
    right: sizeVW-default(54);
  }

  &:before {
    box-sizing: border-box;
    border-right: solid 1px #fff;
    border-bottom: solid 1px #fff;
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    transform: rotate(-225deg);
    transform-origin: 50% 50%;

    @include u-max-default() {
      border-right: solid sizeVW-default(2) #fff;
      border-bottom: solid sizeVW-default(2) #fff;
      height: sizeVW-default(64);
      width: sizeVW-default(64);
    }
  }
}