.c-btnFacebook {
  box-sizing: border-box;
  border: solid 1px #5c5c5c;
  display: block;
  height: 32px;
  width: 32px;

  @include u-max-default() {
    height: sizeVW-default(64);
    width: sizeVW-default(64);
  }

  &:before {
    background: url(../img/common/icon-facebook-g.svg) no-repeat center center;
    background-size: 10px 15px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;

    @include u-max-default() {
      background-size: sizeVW-default(16) sizeVW-default(30);
    }
  }
}

.c-btnFacebook--white {
  border: solid 1px #fff;

  &:before {
    background: url(../img/common/icon-facebook-w.svg) no-repeat center center;
    background-size: 10px 15px;

    @include u-max-default() {
      background-size: sizeVW-default(16) sizeVW-default(30);
    }
  }
}

.c-btnFacebook--black {
  border: solid 1px #727272;

  &:before {
    background: url(../img/common/icon-facebook.svg) no-repeat center center;
    background-size: 10px 15px;

    @include u-max-default() {
      background-size: sizeVW-default(16) sizeVW-default(30);
    }
  }
}

.c-btnFacebook--noborder {
  border: 0;
}