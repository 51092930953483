.c-moduleCase {
  height: 558px;
  width: 100%;
  display: flex;

  @include u-max-default() {
    height: auto;
    display: block;
  }
}

.c-moduleCase__slide {
  position: relative;
  overflow: hidden;
  width: 66%;

  @include u-max-default() {
    width: 100%;
  }

  &:before {
    @include u-max-default() {
      content: '';
      display: block;
      padding-top: (355 / 630) * 100%;
    }
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    img {
      position: relative;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}

.c-moduleCase__info {
  box-sizing: border-box;
  background-color: #fff;
  padding-top: 56px;
  padding-left: 60px;
  width: 34%;
  position: relative;

  @include u-max-default() {
    padding-top: sizeVW-default(50);
    padding-left: sizeVW-default(40);
    width: 100%;
  }

  > h2 {
    @include u-font-size(40);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 1;
    margin-bottom: 41px;

    @include u-max-default() {
      font-size: sizeVW-default(58);
      margin-bottom: sizeVW-default(34);
    }
  }

  > h3 {
    @include u-font-size(16);
    font-weight: 700;
    line-height: (23 / 16);
    margin-bottom: 12px;

    @include u-max-default() {
      font-size: sizeVW-default(26);
      line-height: (38 / 26);
      margin-bottom: sizeVW-default(14);
    }

    span {
      font-style: italic;
      display: block;
    }
  }

  > h4 {
    @include u-font-size(12);
    line-height: (23 / 16);
    margin-bottom: 36px;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (38 / 26);
      margin-bottom: 0;
    }
  }
}

.c-moduleCase__infoView {
  @include u-font-size(14);
  box-sizing: border-box;
  border: solid 1px #000;
  font-weight: 700;
  letter-spacing: 0.14em;
  height: 50px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include u-max-default() {
    display: none;
  }

  &:after {
    background-color: #000;
    content: '';
    display: block;
    height: 1px;
    width: 60px;
    position: absolute;
    top: 24px;
    right: -39px;
  }
}

.c-moduleCase__infoTool {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include u-max-default() {
    position: relative;
    margin-top: sizeVW-default(116);
  }
}

.c-moduleCase__infoTool__seek {
  background-color: #ccc;
  height: 1px;
  width: 100px;

  @include u-max-default() {
    height: sizeVW-default(4);
    width: sizeVW-default(150);
  }
}

.c-moduleCase__infoTool__prev {
  background-color: #000;
  cursor: pointer;
  height: 80px;
  width: 80px;
  margin-left: 20px;
  position: relative;

  @include u-max-default() {
    margin-left: sizeVW-default(30);
    height: sizeVW-default(90);
    width: sizeVW-default(90);
  }

  > span {
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;

    @include u-max-default() {
      height: sizeVW-default(20);
      width: sizeVW-default(20);
      margin-top: sizeVW-default(-10);
      margin-left: sizeVW-default(-10);
    }

    &:after {
      box-sizing: border-box;
      border-top: solid 1px #9e9e9e;
      border-left: solid 1px #9e9e9e;
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      transform: rotate(-45deg);
      transform-origin: 50% 50%;
      margin-top: 1px;
      margin-left: 4px;

      @include u-max-default() {
        border-top: solid sizeVW-default(2) #9e9e9e;
        border-left: solid sizeVW-default(2) #9e9e9e;
        height: sizeVW-default(20);
        width: sizeVW-default(20);
        margin-top: 0;
        margin-left: sizeVW-default(6);
      }
    }
  }
}

.c-moduleCase__infoTool__next {
  background-color: #000;
  cursor: pointer;
  height: 80px;
  width: 80px;
  margin-left: 2px;
  position: relative;

  @include u-max-default() {
    margin-left: sizeVW-default(4);
    height: sizeVW-default(90);
    width: sizeVW-default(90);
  }

  > span {
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;

    @include u-max-default() {
      height: sizeVW-default(20);
      width: sizeVW-default(20);
      margin-top: sizeVW-default(-10);
      margin-left: sizeVW-default(-10);
    }

    &:after {
      box-sizing: border-box;
      border-top: solid 1px #9e9e9e;
      border-left: solid 1px #9e9e9e;
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      transform: rotate(135deg);
      transform-origin: 50% 50%;
      margin-top: 1px;
      margin-left: -6px;

      @include u-max-default() {
        border-top: solid sizeVW-default(2) #9e9e9e;
        border-left: solid sizeVW-default(2) #9e9e9e;
        height: sizeVW-default(20);
        width: sizeVW-default(20);
        margin-top: 0;
        margin-left: sizeVW-default(-8);
      }
    }
  }
}