.c-cate {
  @include u-font-size(12);
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;

  @include u-max-default() {
    font-size: sizeVW-default(22);
    padding-left: sizeVW-default(8);
    padding-right: sizeVW-default(6);
    padding-top: sizeVW-default(4);
    padding-bottom: sizeVW-default(4);
  }
}