
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=da8d2e72-0ad3-4fc1-b53c-a7cd284d1c37");

/*@font-face {
  font-family: "AXIS";
  src: url("../font/9adc0413-55bd-4001-9bc0-f823476aa22e.woff2") format("woff2"), url("../font/25516da4-2e65-456a-bc9c-cc6960f6c384.woff") format("woff"), url("../font/8c439ac8-83ef-4635-a477-df9c64f97b62.ttf") format("truetype");
  font-weight: 200;
  font-style: normal
}

@font-face {
  font-family: "AXIS";
  src: url("../font/3e49aace-6c5a-4a76-a80e-21ab7ad44f14.woff2") format("woff2"), url("../font/2a67f3cb-4bfa-4ec5-9167-73e8c4e78635.woff") format("woff"), url("../font/f09ad952-d955-4c72-a632-1313427634d0.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}*/

@font-face {
  font-family: "ITC";
  src: url("../font/598f64cc-c18c-4969-883d-482515fb6f97.woff2") format("woff2"), url("../font/993fb338-0063-4c2f-8d95-7b6864b58e30.woff") format("woff"), url("../font/6fb96a32-0228-4e57-8971-2db31bc1f48d.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "ITC";
  src: url("../font/a81af59b-a5d9-4e96-a7cc-919403c0d86c.woff2") format("woff2"), url("../font/e93ee223-5d52-4bdf-a113-c6c4c8936824.woff") format("woff"), url("../font/ccdadc2e-26c9-48a5-9c52-9c3cc58e9930.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ITC";
  src: url("../font/PICSConduitCustom-MediumItalic.woff") format("woff"), url("../font/PICSConduitCustom-MediumItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "ITC";
  src: url("../font/PICSConduitCustom-BoldItalic.woff") format("woff"), url("../font/PICSConduitCustom-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

//==================================================
// GLOBAL設定
//==================================================
$fontfamily_ja: 'Noto Sans JP', '游ゴシック体', 'Yu Gothic', '游ゴシック', system-ui, 'Hiragino Kaku Gothic ProN', 'Hiragino Mincho Pro', Meiryo, sans-serif;

/* フォントサイズリセット ベース10px */
html, body {
  font-size: 62.5%;
}

body {
  background-color: rgb(231, 231, 231);
  color: rgb(0, 0, 0);
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  @include u-font-size(14);

  @include u-max-default() {
    font-size: sizeVW-default(26);
  }

  font-family: $fontfamily_ja;

  ::placeholder, select {
    font-family: $fontfamily_ja;
  }

  /*::selection {
    background: rgba(31, 44, 92, 0.2);
  }*/

  .no-ios &.is-open-gnav {
    height: 100vh;
    overflow-y: hidden;
  }

  .ios &.is-open-gnav {

  }
}

.f-ITC-ExLight {
  @include f-ITC-ExLight();
}

.f-ITC-Med {
  @include f-ITC-Med();
}

.f-ITC-MedIt {
  @include f-ITC-MedIt();
}

.f-ITC-BoldIt {
  @include f-ITC-BoldIt();
}

img {
  vertical-align: bottom;
  user-select: none;
}

hr {
  border: 0;
  margin: 0;
  padding: 0;
}

svg {
  display: block;
}

blockquote {
  display: block;
  margin: 0;
  padding: 0;
}

input[type=radio], input[type=checkbox] {
  margin: 0;
  padding: 0;
}

iframe {
  vertical-align: middle;
}

figure {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

main {
  display: block;
}

pre {
  margin: 0;
  padding: 0;
  color: rgb(0, 0, 0);
  font-family: $fontfamily_ja;
  font-display: swap;
}

a {
  color: rgb(0, 0, 0);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

button {
  outline: none;
}
