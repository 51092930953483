.u-none-b {
  display: none;

  &--pc {
    display: block;

    @include u-max-default {
      display: none;
    }
  }

  &--sp {
    display: none;

    @include u-max-default {
      display: block;
    }
  }
}

.u-none-i {
  display: none;

  &--pc {
    display: inline;

    @include u-max-default {
      display: none;
    }
  }

  &--sp {
    display: none;

    @include u-max-default {
      display: inline;
    }
  }
}
