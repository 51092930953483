.p-works {
  box-sizing: border-box;
  padding-left: 90px;
  padding-right: 90px;
  margin-bottom: 160px;
  width: 100%;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    margin-bottom: sizeVW-default(180);
  }
}

.p-worksHead {
  padding-top: 120px;
  margin-bottom: 100px;

  @include u-max-default() {
    padding-top: sizeVW-default(180);
    margin-bottom: sizeVW-default(80);
  }
}

.p-worksHead__title {
  margin-bottom: 40px;
  text-align: center;

  @include u-max-default() {
    margin-bottom: sizeVW-default(59);
  }
}

.p-worksHead__tool {}

.p-worksCase {
  margin-bottom: 80px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(80);
  }
}

.p-worksList {
  @include u-max-default() {
    margin-bottom: sizeVW-default(116);
  }
}

.p-worksPager {
  display: flex;
  justify-content: center;
  align-items: center;

  .navigation {
    width: 100%;
  }

  .pagination {

  }

  .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-numbers {
    @include u-font-size(24);
    box-sizing: border-box;
    background-color: #000;
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.12em;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include u-max-default() {
      display: none;
      font-size: sizeVW-default(28);
      letter-spacing: 0.14em;
      height: sizeVW-default(120);
      width: sizeVW-default(120);
    }

    &:hover {
      background-color: #fff;
      color: #000;
    }

    &.current {
      background-color: #fff;
      color: #000;
    }

    &.prev {
      @include u-text-replace();

      @include u-max-default() {
        display: block;
      }

      &:after {
        box-sizing: border-box;
        border-left: solid 1px #fff;
        border-bottom: solid 1px #fff;
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        transform: rotate(45deg);
        transform-origin: 0% 100%;
        position: absolute;
        top: 25px;
        left: 34px;

        @include u-max-default() {
          border-left: solid sizeVW-default(2) #fff;
          border-bottom: solid sizeVW-default(2) #fff;
          height: sizeVW-default(26);
          width: sizeVW-default(26);
          top: sizeVW-default(36);
          left: sizeVW-default(50);
        }
      }

      &:hover {
        &:after {
          border-left: solid 1px #000;
          border-bottom: solid 1px #000;
        }
      }
    }

    &.next {
      @include u-text-replace();

      @include u-max-default() {
        display: block;
      }

      &:after {
        box-sizing: border-box;
        border-right: solid 1px #fff;
        border-bottom: solid 1px #fff;
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        transform: rotate(-45deg);
        transform-origin: 100% 100%;
        position: absolute;
        top: 25px;
        right: 34px;

        @include u-max-default() {
          border-right: solid sizeVW-default(2) #fff;
          border-bottom: solid sizeVW-default(2) #fff;
          height: sizeVW-default(26);
          width: sizeVW-default(26);
          top: sizeVW-default(36);
          right: sizeVW-default(50);
        }
      }

      &:hover {
        &:after {
          border-right: solid 1px #000;
          border-bottom: solid 1px #000;

          @include u-max-default() {
            border-right: solid sizeVW-default(2) #000;
            border-bottom: solid sizeVW-default(2) #000;
          }
        }
      }
    }

    &.dots {

    }
  }
}
