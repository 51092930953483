.p-privacy {
  margin-bottom: 180px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(190);
  }
}

.p-privacy__head {
  padding-top: 114px;
  margin-bottom: 114px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include u-max-default() {
    padding-top: sizeVW-default(160);
    margin-bottom: sizeVW-default(90);
    justify-content: center;
  }

  h2 {
    @include u-font-size(16);
    letter-spacing: 0.14em;
    margin-top: 54px;

    @include u-max-default() {
      font-size: sizeVW-default(26);
      line-height: (44 / 26);
      margin-top: sizeVW-default(54);
    }
  }
}

.p-privacy__con {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 160px;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    margin-bottom: sizeVW-default(160);
  }
}

.p-privacy__conWrap {
  box-sizing: border-box;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding-top: 54px;
  padding-bottom: 58px;

  @include u-max-default() {
    padding-top: sizeVW-default(54);
    padding-bottom: sizeVW-default(54);
  }

  &:first-child {
    border-top: solid 1px rgba(0, 0, 0, 0.1);
  }

  p {
    @include u-font-size(14);
    line-height: (28 / 14);
    letter-spacing: 0.14em;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (38 / 22);
    }

    & + h2,
    & + dl,
    & + p {
      margin-top: 2em;
    }
  }

  h2 {
    @include u-font-size(14);
    font-weight: bold;
    font-style: italic;
    line-height: (28 / 14);
    letter-spacing: 0.14em;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (38 / 22);
    }
  }

  dl {
    @include u-font-size(14);
    line-height: (28 / 14);
    letter-spacing: 0.14em;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (38 / 22);
    }

    & + h2 {
      margin-top: 2em;
    }
  }

  ul {
    @include u-font-size(14);
    line-height: (28 / 14);
    letter-spacing: 0.14em;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (38 / 22);
    }

    & + p {
      margin-top: 2em;
    }

    li {
      position: relative;
      padding-left: 20px;

      @include u-max-default() {
        padding-left: sizeVW-default(26);
      }

      span {
        position: absolute;
        left: 0;
      }
    }
  }
}

.p-privacy__other {
  box-sizing: border-box;
  max-width: 1680px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}