.p-memberEntry {
  margin-bottom: 180px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(190);
  }
}

.p-memberEntry__con {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 148px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 160px;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    padding-top: sizeVW-default(190);
    margin-bottom: sizeVW-default(80);
  }
}

.p-memberEntry__conTitle {
  @include u-font-size(38);
  font-weight: 700;
  letter-spacing: 0.08em;

  @include u-max-default() {
    font-size: sizeVW-default(48);
    letter-spacing: 0.1em;
  }

  > span {
    display: block;
    margin-top: -7px;
    margin-bottom: -9px;

    @include u-max-default() {
      margin-top: sizeVW-default(-7);
      margin-bottom: sizeVW-default(-9);
    }
  }
}

.p-memberEntry__conCaption {
  @include u-font-size(14);
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.14em;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  @include u-max-default() {
    font-size: sizeVW-default(22);
    letter-spacing: 0.04em;
    margin-bottom: sizeVW-default(22);
  }

  &:before {
    background-color: #8c8c8c;
    content: '';
    display: block;
    height: 1px;
    width: 10px;
    margin-right: 4px;

    @include u-max-default() {
      height: sizeVW-default(2);
      width: sizeVW-default(20);
      margin-right: sizeVW-default(8);
    }
  }
}

.p-memberEntry__conLine {
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
  height: 1px;
  width: 100%;

  @include u-max-default() {
    height: sizeVW-default(2);
  }

  &:before {
    background-color: rgba(0, 0, 0, 1.0);
    content: '';
    display: block;
    height: 1px;
    width: 100px;

    @include u-max-default() {
      height: sizeVW-default(2);
      width: sizeVW-default(140);
    }
  }
}

.p-memberEntry__conProfile {
  margin-bottom: 80px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(80);
  }
}

.p-memberEntry__conProfile__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: 40px;

  @include u-max-default() {
    display: block;
    margin-top: sizeVW-default(40);
  }
}

.p-memberEntry__conProfile__wrapPhoto {
  position: relative;
  width: 50%;

  @include u-max-default() {
    width: 100%;
  }

  &:before {
    content: '';
    display: block;
    padding-top: (355 / 630) * 100%;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
  }
}

.p-memberEntry__conProfile__wrapInfo {
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  padding-left: 60px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include u-max-default() {
    padding-left: sizeVW-default(50);
    padding-right: sizeVW-default(50);
    padding-top: sizeVW-default(60);
    padding-bottom: sizeVW-default(50);
    width: 100%;
  }

  > h2 {
    margin-top: 20px;

    @include u-max-default() {
      margin-top: sizeVW-default(20);
    }

    span {
      display: block;

      &:nth-child(1) {
        @include u-font-size(40);
        font-weight: 700;
        letter-spacing: 0.1em;

        @include u-max-default() {
          font-size: sizeVW-default(56);
          line-height: (68 / 56);
        }
      }
      &:nth-child(2) {
        @include u-font-size(14);
        font-weight: 700;
        letter-spacing: 0.06em;
        margin-top: 18px;

        @include u-max-default() {
          font-size: sizeVW-default(26);
          line-height: (38 / 25);
          margin-top: sizeVW-default(18);
        }
      }
    }
  }

  > h3 {
    @include u-font-size(14);
    line-height: (26 / 14);
    letter-spacing: 0.1em;
    margin-top: 18px;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (38 / 22);
      margin-top: sizeVW-default(22);
    }

    span {
      & + span {
        &:before {
          content: ' / ';
        }
      }
    }
  }
}

.p-memberEntry__conBio {
  box-sizing: border-box;
}

.p-memberEntry__conBio__text {
  margin-top: 32px;

  @include u-max-default() {
    margin-top: sizeVW-default(35);
  }

  > dt {
    @include u-font-size(14);
    line-height: (38 / 14);
    letter-spacing: 0.14em;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
    }
  }

  > dd {
    @include u-font-size(14);
    line-height: (38 / 14);
    letter-spacing: 0.14em;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
    }
  }
}

.p-memberEntry__conBio__sns {
  display: flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 80px;

  @include u-max-default() {
    display: block;
    margin-top: sizeVW-default(24);
    margin-bottom: sizeVW-default(100);
  }

  > h4 {
    @include u-font-size(18);
    font-weight: 700;
    letter-spacing: 0.04em;

    @include u-max-default() {
      font-size: sizeVW-default(28);
    }
  }

  > ul {
    display: flex;
    align-items: center;
    margin-left: 18px;

    @include u-max-default() {
      margin-left: 0;
      margin-top: sizeVW-default(16);
    }

    li {
      & + li {
        margin-left: 18px;

        @include u-max-default() {
          margin-left: sizeVW-default(10);
        }
      }
    }
  }
}

.p-memberEntry__conWorks {
  margin-top: 80px;

  @include u-max-default() {
    margin-top: sizeVW-default(58);
  }

  ul {
    margin-bottom: 92px;

    @include u-max-default() {
      margin-bottom: sizeVW-default(92);
    }

    li {
      @include u-font-size(12);
      line-height: (26 / 12);
      position: relative;
      padding-left: 12px;

      @include u-max-default() {
        font-size: sizeVW-default(22);
        line-height: (38 / 22);
        padding-left: sizeVW-default(22);
      }

      &:before {
        content: '・';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      & + li {
        margin-top: 2px;

        @include u-max-default() {
          margin-top: sizeVW-default(3);
        }
      }
    }
  }
}

.p-memberEntry__carousel {

}

.p-memberEntry__other {
  box-sizing: border-box;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}