.p-company {
  margin-bottom: 180px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(180);
  }
}

.p-company__title {
  text-align: center;
  height: 745px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include u-max-default() {
    height: sizeVW-default(858);
  }

  > h1 {
    @include u-font-size(98);
    font-weight: 700;
    letter-spacing: 0.06em;

    @include u-max-default() {
      font-size: sizeVW-default(100);
      letter-spacing: 0.12em;
    }
  }

  > p {
    @include u-font-size(24);
    font-weight: 700;
    line-height: (42 / 24);
    letter-spacing: 0.1em;
    margin-top: 28px;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
      letter-spacing: 0.1em;
      padding-left: sizeVW-default(70);
      padding-right: sizeVW-default(70);
      margin-top: sizeVW-default(36);
    }
  }
}

.p-company__caption {
  @include u-font-size(38);
  font-weight: 700;
  letter-spacing: 0.06em;
}

.p-company__con {
  box-sizing: border-box;
  max-width: 1680px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include u-max-default() {
    padding-left: 0;
    padding-right: 0;
  }
}

.p-company__conWrap {
  background-color: #fff;
  padding-top: 100px;
  padding-left: 150px;
  padding-right: 150px;

  @include u-max-default() {
    padding-top: sizeVW-default(100);
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}

.p-company__conPhilo {
  background-color: #fff;
  height: 282px;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;

  dl {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    dt {
      height: 100%;
      width: (530 / 1500) * 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    dd {
      @include u-font-size(28);
      font-weight: 700;
      line-height: (54 / 28);
      letter-spacing: 0.16em;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.p-company__conWhat {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 94px;

  @include u-max-default() {

  }
}

.p-company__conWhat__title {
  display: flex;
}

.p-company__conWhat__ceo {
  @include u-font-size(20);
  font-weight: 700;
  letter-spacing: 0.1em;
  background-color: #000;
  color: #fff;
  height: 70px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 260px;
  position: relative;

  @include u-max-default() {

  }

  &:before {
    background-color: #000;
    content: '';
    display: block;
    height: 2px;
    width: 200px;
    position: absolute;
    right: 50%;
    bottom: -35px;
  }

  &:after {
    background-color: #000;
    content: '';
    display: block;
    height: 320px;
    width: 2px;
    position: absolute;
    bottom: -320px;
    left: 50%;
    margin-left: -1px;
  }
}

.p-company__conWhat__general {
  width: 400px;

  h4 {
    @include u-font-size(20);
    font-weight: 700;
    letter-spacing: 0.1em;
    background-color: #000;
    color: #fff;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.p-company__conWhat__generalList {
  background-color: #fff;
  padding-top: 34px;
  padding-bottom: 34px;

  li {
    @include u-font-size(20);
    font-weight: 700;
    line-height: (30 / 20);
    letter-spacing: 0.04em;
    text-align: center;
  }
}

.p-company__conWhat__production {
  margin-top: 30px;
}

.p-company__conWhat__productionTitle {
  background-color: #000;
  color: #fff;
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    &:nth-child(1) {
      @include u-font-size(24);
      font-weight: 700;
      letter-spacing: 0.06em;
    }

    &:nth-child(2) {
      @include u-font-size(14);
      line-height: (28 / 14);
      letter-spacing: 0.14em;
    }
  }
}

.p-company__conWhat__productionList {
  background-color: #fff;
  padding-top: 34px;
  padding-bottom: 34px;

  li {
    @include u-font-size(20);
    font-weight: 700;
    line-height: (30 / 20);
    letter-spacing: 0.04em;
    text-align: center;
  }
}

.p-company__conWhat__div {
  margin-top: 30px;

  > ul {
    display: flex;
    align-items: stretch;
  }
}

.p-company__conWhat__divSet {
  margin-right: 2.4%;
  width: 23.2%;

  &:nth-child(4n+0) {
    margin-right: 0;
  }

  > p {
    @include u-font-size(14);
    line-height: (28 / 14);
    letter-spacing: 0.14em;
    margin-top: 24px;
  }
}

.p-company__conWhat__divTitle {
  background-color: #000;
  color: #fff;
  height: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-talent {
    height: 50px;
    width: 131px;
  }
}

.p-company__conWhat__divList {
  box-sizing: border-box;
  background-color: #fff;
  min-height: 250px;
  padding-top: 34px;

  li {
    @include u-font-size(20);
    font-weight: 700;
    line-height: (30 / 20);
    letter-spacing: 0.04em;
    text-align: center;
  }
}

.p-company__production {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: sizeVW(-240, 1680);
  position: relative;
}

.p-company__productionWrap {
  box-sizing: border-box;
  background-color: #fff;
  max-width: 850px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 50px;
  padding-bottom: 60px;
  margin-left: auto;

  > h3 {

  }

  > p {
    @include u-font-size(14);
    line-height: (28 / 14);
    letter-spacing: 0.14em;
  }

  > dl {
    padding-left: 24px;
    position: relative;

    dt {
      @include u-font-size(14);
      font-style: italic;
      font-weight: 700;
      line-height: (28 / 14);
      letter-spacing: 0.14em;
      display: flex;
      align-items: center;
      margin-left: -24px;

      &:before {
        background-color: #999;
        content: '';
        height: 1px;
        width: 20px;
        margin-right: 4px;
      }
    }
    dd {
      @include u-font-size(14);
      line-height: (28 / 14);
      letter-spacing: 0.14em;
    }
  }
}

.p-company__service {
  background-color: #000;
  color: #fff;
}

.p-company__serviceWrap {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 76px;
  padding-bottom: 100px;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {

  }
}

.p-company__serviceWrap__list {
  margin-top: 38px;
  border-bottom: solid 1px #666;

  > li {
    box-sizing: border-box;
    border-top: solid 1px #666;
    display: flex;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 36px;
    min-height: 160px;

    .p-company__serviceWrap__listTitle {
      @include u-font-size(28);
      font-weight: 700;
      letter-spacing: 0.08em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-basis: 160px;
      min-width: 160px;
      width: 160px;
      margin-right: 96px;

      &:after {
        background: url(../img/common/icon-logo.svg) no-repeat left top;
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 31px;
        width: 10px;
      }
    }

    .p-company__serviceWrap__listInfo {
      padding-right: 140px;

      h5 {
        @include u-font-size(20);
        line-height: (28 / 20);
        letter-spacing: 0.14em;
      }

      p {
        @include u-font-size(14);
        line-height: (28 / 14);
        letter-spacing: 0.14em;
        margin-top: 8px;
      }
    }
  }
}

.p-company__awards {

}

.p-company__awardsWrap {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 76px;
  padding-bottom: 100px;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {

  }
}

.p-company__awardsWrap__year {
  display: flex;
  margin-top: 38px;

  & + .p-company__awardsWrap__year {
    margin-top: 40px;
  }

  > h4 {
    @include u-font-size(28);
    font-weight: 700;
    letter-spacing: 0.08em;
  }

  > dl {
    border-left: solid 1px #8b8b8b;
    padding-left: 74px;
    margin-left: 48px;

    dt {
      @include u-font-size(14);
      font-weight: 700;
      font-style: italic;
      line-height: (28 / 14);
      letter-spacing: 0.14em;
      display: flex;
      align-items: center;
      margin-left: -24px;

      &:before {
        background-color: #999;
        content: '';
        height: 1px;
        width: 20px;
        margin-right: 4px;
      }
    }

    dd {
      @include u-font-size(14);
      line-height: (28 / 14);
      letter-spacing: 0.14em;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.p-company__info {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: sizeVW(-70, 1680);
  position: relative;

  @include u-max-default() {

  }
}

.p-company__infoWrap {
  box-sizing: border-box;
  background-color: #fff;
  max-width: 850px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 50px;
  padding-bottom: 60px;
  margin-left: auto;

  @include u-max-default() {

  }
}

.p-company__infoWrap__list {
  margin-top: 36px;

  > dl {
    display: flex;
    box-sizing: border-box;
    border-top: solid 1px #999;
    padding-top: 32px;
    padding-bottom: 34px;
    min-height: 94px;

    &:last-child {
      border-bottom: solid 1px #999;
    }

    & + dl {

    }

    dt {
      @include u-font-size(14);
      font-weight: 700;
      font-style: italic;
      line-height: (28 / 14);
      letter-spacing: 0.14em;
      flex-basis: (244 / 730) * 100%;
      min-width: (244 / 730) * 100%;
      width: (244 / 730) * 100%;
      display: flex;
      align-items: center;
    }

    dd {
      @include u-font-size(14);
      line-height: (28 / 14);
      letter-spacing: 0.14em;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}

.p-company__map {
  background-color: #007aff;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-top: (560 / 1680) * 100%;

    @include u-max-default() {
      padding-top: (560 / 750) * 100%;
    }
  }
}

.p-company__mapWrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.p-company__clients {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-top: sizeVW(-60, 1680);
  margin-bottom: 120px;
  position: relative;

  @include u-max-default() {

  }
}

.p-company__clientsWrap {
  background-color: #fff;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 52px;
  padding-bottom: 54px;

  @include u-max-default() {

  }
}

.p-company__clientsWrap__list {
  border-top: solid 1px #999;
  display: flex;
  margin-top: 36px;
  padding-top: 42px;

  ul {
    @include u-font-size(14);
    line-height: (28 / 14);
    letter-spacing: 0.14em;
    width: 50%;
  }
}

.p-company__v {
  width: 100%;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.p-company__other {
  box-sizing: border-box;
  max-width: 1680px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}
