.c-refine {
  height: 40px;
  width: 380px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;

  @include u-max-default() {
    height: sizeVW-default(84);
    width: 100%;
  }
}

.c-refine__wrap {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;

  &:before {
    background-color: #000;
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;

    @include u-max-default() {
      height: sizeVW-default(2);
      visibility: visible;
    }
  }

  &:after {
    background-color: #000;
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    @include u-max-default() {
      height: sizeVW-default(2);
    }
  }
}

.c-refine__wrapCurrent {
  @include u-font-size(20);
  letter-spacing: 0.14em;
  position: relative;

  @include u-max-default() {
    font-size: sizeVW-default(30);
  }

  span {
    margin-left: 5px;
    margin-right: 6px;

    @include u-max-default() {
      margin-left: sizeVW-default(8);
      margin-right: sizeVW-default(8);
    }
  }
}

.c-refine__arrow {
  box-sizing: border-box;
  height: 13px;
  width: 13px;
  position: absolute;
  top: 11px;
  right: 4px;
  //left: 0;

  @include u-max-default() {
    top: sizeVW-default(22);
    right: sizeVW-default(6);
    height: sizeVW-default(26);
    width: sizeVW-default(26);
  }

  &:before {
    box-sizing: border-box;
    border-right: solid 2px #000;
    border-bottom: solid 2px #000;
    content: '';
    display: block;
    height: 13px;
    width: 13px;
    transform: rotate(45deg);
    transform-origin: 50% 50%;

    @include u-max-default() {
      border-right: solid sizeVW-default(2) #000;
      border-bottom: solid sizeVW-default(2) #000;
      height: sizeVW-default(26);
      width: sizeVW-default(26);
    }
  }
}

.c-refine__list {
  position: absolute;
  top: 40px;
  left: -((600 - 380) / 2) + px;

  @include u-max-default() {
    top: sizeVW-default(104);
    left: sizeVW-default(-60);
    right: sizeVW-default(-60);
  }
}

.c-refine__listCates {
  box-sizing: border-box;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 600px;

  @include u-max-default() {
    padding-top: sizeVW-default(20);
    padding-bottom: sizeVW-default(20);
    padding-left: sizeVW-default(20);
    padding-right: sizeVW-default(20);
    width: 100%;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    @include u-max-default() {
      margin-bottom: sizeVW-default(-20);
    }

    li {
      height: 60px;
      width: 285px;
      margin-right: 10px;
      margin-bottom: 10px;

      @include u-max-default() {
        height: sizeVW-default(80);
        width: sizeVW-default(345);
        margin-right: sizeVW-default(20);
        margin-bottom: sizeVW-default(20);
      }

      &:nth-child(2n+0) {
        margin-right: 0;
      }

      a {
        @include u-font-size(20);
        box-sizing: border-box;
        letter-spacing: 0.06em;
        background-color: #e9e9e9;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        @include u-max-default() {
          font-size: sizeVW-default(30);
        }

        &.isSelected {
          background-color: #fff;
          border: solid 1px #000;
        }
      }
    }
  }
}

.c-refine__listTags {
  background-color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;

  @include u-max-default() {
    padding-top: sizeVW-default(20);
    padding-bottom: sizeVW-default(20);
    padding-left: sizeVW-default(20);
    padding-right: sizeVW-default(20);
  }
}

.c-refine__listTags__wrap {
  font-size: 0;//隙間対策
  margin-bottom: -10px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(-20);
  }

  a {
    @include u-font-size(14);
    background-color: #333;
    color: #fff;
    letter-spacing: 0.06em;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      height: sizeVW-default(68);
      padding-left: sizeVW-default(28);
      padding-right: sizeVW-default(28);
      margin-bottom: sizeVW-default(20);
      margin-right: sizeVW-default(20);
    }

    &.isSelected {
      background-color: #fff;
      color: #000
    }
  }
}

.c-refine__listClose {
  box-sizing: border-box;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
  height: 81px;
  width: 81px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @include u-max-default() {
    height: sizeVW-default(140);
    width: sizeVW-default(140);
    margin-top: sizeVW-default(30);
  }
}

.c-refine__listClose__cross {
  height: 39px;
  width: 39px;
  position: relative;
  transform-origin: 50% 50%;
  transform: rotate(45deg);

  @include u-max-default() {
    height: sizeVW-default(68);
    width: sizeVW-default(68);
  }

  span {
    position: absolute;

    &:nth-child(1) {
      background-color: #fff;
      display: block;
      height: 1px;
      width: 100%;
      top: 19px;
      left: 0;

      @include u-max-default() {
        height: sizeVW-default(2);
        top: sizeVW-default(33);
      }
    }

    &:nth-child(2) {
      background-color: #fff;
      display: block;
      height: 100%;
      width: 1px;
      top: 0;
      left: 19px;

      @include u-max-default() {
        width: sizeVW-default(2);
        left: sizeVW-default(33);
      }
    }
  }
}