.p-recruitEntry {
  margin-bottom: 180px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(190);
  }
}

.p-recruitEntry__mv {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: (745 / 1680) * 100%;

    @include u-max-default() {
      padding-top: (856 / 750) * 100%;
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: 50% 50%;
  }
}