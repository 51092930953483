.p-contact {
  margin-bottom: 180px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(190);
  }

  .isRed {
    color: #e8302c;
  }
}

.p-contactMv {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: (745 / 1680) * 100%;

    @include u-max-default() {
      padding-top: (856 / 750) * 100%;
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.p-contactMv__title {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.p-contactHead {
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 86px;
  margin-left: auto;
  margin-right: auto;
  //margin-bottom: 80px;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    padding-top: sizeVW-default(90);
    //margin-bottom: sizeVW-default(60);
  }
}

.p-contactHead__notice {
  @include u-font-size(14);
  line-height: (38 / 14);
  letter-spacing: 0.14em;
  margin-bottom: 90px;

  @include u-max-default() {
    font-size: sizeVW-default(24);
    line-height: (48 / 24);
    letter-spacing: 0.1em;
    margin-bottom: sizeVW-default(90);
  }
}

.p-contactHead__process {
  display: flex;
  width: 100%;

  li {
    @include u-font-size(18);
    color: #888;
    font-weight: 700;
    letter-spacing: 0.1em;
    position: relative;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      height: sizeVW-default(54);
      letter-spacing: 0.06em;
    }

    &:before {
      background-color: rgba(0, 0, 0, 0.1);
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;

      @include u-max-default() {
        height: sizeVW-default(2);
      }
    }

    &:after {
      background-color: #000;
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      visibility: hidden;

      @include u-max-default() {
        height: sizeVW-default(2);
      }
    }

    &.isCurrent {
      color: #000;

      &:after {
        visibility: visible;
      }
    }
  }
}

.p-contactFrm-enter-active {
  transition: opacity 0.4s $_3_CubicOut;
}

.p-contactFrm-enter {
  opacity: 0;
}

.p-contactFrm-enter-to {
  opacity: 1;
}

.p-contactFrm-leave-active {
  transition: opacity 0.4s $_3_CubicOut;
}

.p-contactFrm-leave {
  opacity: 1;
}

.p-contactFrm-leave-to {
  opacity: 0;
}

.p-contactFrm {
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  //margin-top: -40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    padding-top: 20px;
    //margin-top: sizeVW-default(-40);
    display: block;
  }
}

.p-contactFrm__input {
  width: 100%;
  margin-top: 40px;

  @include u-max-default() {
    margin-top: sizeVW-default(40);
  }
}

.p-contactFrm__input--agree {
  margin-top: 58px;
  margin-bottom: 78px;

  @include u-max-default() {
    margin-top: sizeVW-default(58);
    margin-bottom: sizeVW-default(80);
  }
}

.p-contactFrm__input--half {
  width: (580 / 1200) * 100%;

  @include u-max-default() {
    width: 100%;
  }
}

.p-contactFrm__input--bottom {
  margin-bottom: 100px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(100);
  }
}

.p-contactFrm__input--center {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
}

.p-contactFrm__inputWrap {
  background-color: #fff;
  position: relative;

  label {
    @include u-font-size(14);
    box-sizing: border-box;
    color: #999;
    letter-spacing: 0.1em;
    padding-left: 30px;
    padding-top: 0;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      padding-left: sizeVW-default(28);
    }

    span {
      &.isRequired {
        &:after {
          color: #e8302c;
          content: '＊';
          display: inline;
        }
      }
    }
  }

  input[type=text],input[type=email], select {
    @include u-font-size(14);
    letter-spacing: 0.1em;
    padding-left: 30px;
    padding-right: 30px;
    appearance: none;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 0;
    border: 0;
    height: 80px;
    width: 100%;
    position: relative;
    outline: none;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      padding-left: sizeVW-default(28);
      padding-right: sizeVW-default(20);
      height: sizeVW-default(100);
    }
  }

  textarea {
    @include u-font-size(14);
    line-height: (30 / 14);
    letter-spacing: 0.1em;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    appearance: none;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 0;
    border: 0;
    height: 360px;
    width: 100%;
    position: relative;
    outline: none;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
      padding-left: sizeVW-default(28);
      padding-right: sizeVW-default(20);
      padding-top: sizeVW-default(20);
      padding-bottom: sizeVW-default(20);
      height: sizeVW-default(510);
    }
  }
}

.p-contactFrm__input--error {
  input[type=text],input[type=email], select, textarea {
    background-color: rgba(255, 0, 0, 0.1);
  }

  .p-contactFrm__agree__check {
    background-color: rgba(255, 0, 0, 0.1);
  }
}

.p-contactFrm__inputWrap--ta {
  label {
    padding-top: 32px;
    align-items: flex-start;

    @include u-max-default() {
      padding-top: sizeVW-default(28);
    }
  }
}

.p-contactFrm__inputError {
  @include u-font-size(14);
  color: #e8302c;
  letter-spacing: 0.1em;
  margin-top: 10px;
  //display: none;

  @include u-max-default() {
    font-size: sizeVW-default(24);
    margin-top: sizeVW-default(10);
  }
}

.p-contactFrm__inputError--center {
  text-align: center;
}

.p-contactFrm__inputNotice {
  @include u-font-size(14);
  color: #888;
  letter-spacing: 0.1em;
  margin-top: 18px;

  @include u-max-default() {
    font-size: sizeVW-default(24);
    margin-top: sizeVW-default(24);
  }
}

.p-contactFrm__inputNotice--center {
  text-align: center;
}

.p-contactFrm__agree {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  input[type=checkbox] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
  }
}

.p-contactFrm__agree__check {
  box-sizing: border-box;
  border: solid 1px #000;
  cursor: pointer;
  height: 20px;
  flex-basis: 20px;
  min-width: 20px;
  width: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  @include u-max-default() {
    border: solid sizeVW-default(2) #000;
    height: sizeVW-default(30);
    flex-basis: sizeVW-default(30);
    min-width: sizeVW-default(30);
    width: sizeVW-default(30);
    margin-right: sizeVW-default(20);
  }

  &:before {
    box-sizing: border-box;
    background-color: #000;
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    visibility: hidden;

    @include u-max-default() {
      height: sizeVW-default(18);
      width: sizeVW-default(18);
    }
  }

  &.isChecked {
    &:before {
      visibility: visible;
    }
  }
}

.p-contactFrm__agree__text {
  @include u-font-size(14);
  letter-spacing: 0.1em;

  @include u-max-default() {
    font-size: sizeVW-default(24);
    line-height: (48 / 24);
  }

  a {
    text-decoration: underline;
  }
}

.p-contactFrm__inputWrap__btn {
  background-color: #000;
  height: 80px;
  width: 80px;
  position: absolute;
  top: 0;
  right: 0;

  @include u-max-default() {
    height: sizeVW-default(100);
    width: sizeVW-default(100);
  }
}

.p-contactFrm__inputWrap__btnArrow {
  display: block;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -12px;
  margin-left: -8px;

  @include u-max-default() {
    height: sizeVW-default(26);
    width: sizeVW-default(26);
    margin-top: sizeVW-default(-18);
    margin-left: sizeVW-default(-13);
  }

  &:before {
    box-sizing: border-box;
    border-right: solid 1px #fff;
    border-bottom: solid 1px #fff;
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    transform: rotate(45deg);
    transform-origin: 50% 50%;

    @include u-max-default() {
      border-right: solid sizeVW-default(2) #fff;
      border-bottom: solid sizeVW-default(2) #fff;
      height: sizeVW-default(26);
      width: sizeVW-default(26);
    }
  }
}

.p-contactFrm__btn {
  display: flex;
  //flex-wrap: wrap;
  width: 100%;
}

.p-contactFrm__btnBack {
  flex-basis: 350px;
  min-width: 350px;
  width: 350px;
  margin-right: 40px;
}

.p-contactFrm__btnSubmit {
  width: 100%;
}

.p-contactComp {
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 78px;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    margin-top: sizeVW-default(56);
    display: block;
  }

  h1 {
    @include u-font-size(18);
    font-weight: bold;
    line-height: (48 / 18);
    letter-spacing: 0.1em;
    text-align: center;
    margin-top: -14px;

    @include u-max-default() {
      font-size: sizeVW-default(28);
      line-height: (60 / 28);
      margin-top: 0;
      padding-top: sizeVW-default(30);
    }
  }

  p {
    @include u-font-size(14);
    line-height: (38 / 14);
    letter-spacing: 0.1em;
    text-align: center;
    margin-top: 34px;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (57 / 24);
      margin-top: sizeVW-default(52);
    }
  }

  p + p {
    margin-top: 2.8em;

    @include u-max-default() {
      margin-top: 2.3em;
    }
  }
}

.p-contactComp__btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 68px;

  @include u-max-default() {
    margin-top: sizeVW-default(84);
  }
}

.p-contactFrm__inputResult {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  padding-bottom: 36px;
  margin-top: -8px;

  @include u-max-default() {
    padding-bottom: sizeVW-default(36);
    margin-top: sizeVW-default(-8);
  }

  &:after {
    background-color: rgba(0, 0, 0, 0.1);
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    @include u-max-default() {
      height: sizeVW-default(2);
    }
  }

  dt {
    @include u-font-size(14);
    font-weight: bold;
    line-height: (28 / 14);
    letter-spacing: 0.1em;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
    }
  }

  dd {
    @include u-font-size(14);
    line-height: (28 / 14);
    letter-spacing: 0.1em;
    margin-top: 4px;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
    }

    pre {
      white-space: pre-wrap;
    }
  }
}