.p-member {
  box-sizing: border-box;
  padding-left: 90px;
  padding-right: 90px;
  margin-bottom: 160px;
  width: 100%;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
    margin-bottom: sizeVW-default(180);
  }
}

.p-memberHead {
  padding-top: 120px;
  margin-bottom: 102px;

  @include u-max-default() {
    padding-top: sizeVW-default(180);
    margin-bottom: sizeVW-default(80);
  }
}

.p-memberHead__title {
  margin-bottom: 40px;
  text-align: center;

  @include u-max-default() {
    margin-bottom: sizeVW-default(59);
  }
}

.p-memberHead__tool {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include u-max-default() {
    justify-content: space-between;
  }
}

.p-memberHead__toolCate {
  display: flex;
  align-items: flex-start;
  position: relative;

  @include u-max-default() {
    border-top: solid sizeVW-default(2) #000;
    border-bottom: solid sizeVW-default(2) #000;
    padding-top: sizeVW-default(28);
    padding-bottom: sizeVW-default(28);
    width: sizeVW-default(430);
  }

  > h2 {
    @include u-font-size(20);
    font-weight: 700;
    letter-spacing: 0.14em;
    display: flex;
    align-items: center;

    @include u-max-default() {
      font-size: sizeVW-default(30);
    }

    &:after {
      content: ':';
      display: block;
      margin-top: -5px;
      margin-left: 6px;
      margin-right: 6px;

      @include u-max-default() {
        margin-top: sizeVW-default(-6);
        margin-left: sizeVW-default(10);
        margin-right: sizeVW-default(6);
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;

    @include u-max-default() {
      display: none;
    }

    li {
      @include u-font-size(20);
      font-weight: 700;
      letter-spacing: 0.14em;
      display: flex;
      align-items: center;

      a {
        position: relative;

        &:after {
          background-color: #000;
          content: '';
          display: block;
          height: 2px;
          width: 100%;
          margin-top: 3px;
          margin-left: -3px;
          visibility: hidden;
        }

        &.isSelected {
          &:after {
            visibility: visible;
          }
        }
      }
    }

    li + li {
      &:before {
        content: '/';
        display: block;
        margin-left: 8px;
        margin-right: 9px;
        margin-top: -5px;
      }
    }
  }
}

.p-memberHead__toolCate__current {
  display: none;

  @include u-max-default() {
    font-size: sizeVW-default(30);
    display: block;
    letter-spacing: 0.14em;
  }
}

.p-memberHead__toolCate__arrow {
  display: none;

  @include u-max-default() {
    display: block;
    position: absolute;
    top: sizeVW-default(16);
    right: sizeVW-default(8);
    height: sizeVW-default(28);
    width: sizeVW-default(28);

    &:before {
      border-bottom: solid sizeVW-default(2) #000;
      border-left: solid sizeVW-default(2) #000;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: sizeVW-default(28);
      width: sizeVW-default(28);
      transform-origin: 50% 50%;
      transform: rotate(-45deg);
    }
  }
}

.p-memberHead__toolTag {
  @include u-font-size(20);
  font-weight: 700;
  letter-spacing: 0.14em;
  display: flex;
  align-items: center;

  @include u-max-default() {
    border-top: solid sizeVW-default(2) #000;
    border-bottom: solid sizeVW-default(2) #000;
    padding-top: sizeVW-default(28);
    padding-bottom: sizeVW-default(28);
    font-size: sizeVW-default(30);
    width: sizeVW-default(170);
    justify-content: center;
  }

  &:before {
    background-color: #000;
    content: '';
    display: block;
    height: 1px;
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;

    @include u-max-default() {
      margin-left: sizeVW-default(4);
      margin-right: sizeVW-default(10);
      height: sizeVW-default(2);
      width: sizeVW-default(40);
    }
  }
}

.p-memberLead {
  background-color: #fff;
  margin-bottom: 100px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(80);
  }

  dl {
    display: flex;
    align-items: center;

    @include u-max-default() {
      display: block;
      padding-left: sizeVW-default(40);
      padding-right: sizeVW-default(40);
      padding-top: sizeVW-default(80);
      padding-bottom: sizeVW-default(90);
    }

    dt {
      height: 100%;
      flex-basis: (530 / 1500) * 100%;
      min-width: (530 / 1500) * 100%;
      width: (530 / 1500) * 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include u-max-default() {
        display: block;
        min-width: 100%;
        width: 100%;
        margin-bottom: sizeVW-default(48);
      }
    }

    dd {
      box-sizing: border-box;
      padding-top: 70px;
      padding-bottom: 70px;
      padding-right: 30px;
      height: 100%;
      width: 100%;

      @include u-max-default() {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
      }

      h3 {
        @include u-font-size(34);
        font-weight: bold;
        line-height: (60 / 34);
        letter-spacing: 0.1em;

        @include u-max-default() {
          font-size: sizeVW-default(36);
          line-height: (56 / 36);
          margin-bottom: sizeVW-default(30);
        }
      }

      p {
        @include u-font-size(14);
        line-height: (38 / 14);
        letter-spacing: 0.14em;

        @include u-max-default() {
          font-size: sizeVW-default(24);
          line-height: (48 / 24);
        }
      }
    }
  }
}

.p-memberList {
  @include u-max-default() {
    margin-bottom: sizeVW-default(116);
  }
}

.p-memberList__title {
  @include u-font-size(38);
  letter-spacing: 0.04em;
  margin-bottom: 42px;

  @include u-max-default() {
    font-size: sizeVW-default(58);
    margin-bottom: sizeVW-default(42);
  }
}
