.p-worksEntry {
  margin-bottom: 180px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(190);
  }
}

.p-worksEntry__mv {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: (745 / 1680) * 100%;

    @include u-max-default() {
      padding-top: (856 / 750) * 100%;
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.p-worksEntry__con {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}

.p-worksEntry__conTitle {
  padding-top: 72px;
  margin-bottom: 74px;
  text-align: center;

  @include u-max-default() {
    padding-top: sizeVW-default(48);
    margin-bottom: sizeVW-default(56);
    text-align: left;
  }

  > h1 {
    @include u-font-size(38);
    font-weight: 700;
    letter-spacing: 0.14em;
    line-height: (52 / 38);

    @include u-max-default() {
      font-size: sizeVW-default(36);
      letter-spacing: 0.1em;
      line-height: (56 / 36);
    }
  }

  > h2 {
    @include u-font-size(16);
    letter-spacing: 0.14em;
    line-height: (30 / 16);
    margin-top: 18px;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      letter-spacing: 0.06em;
      line-height: (40 / 22);
      margin-top: sizeVW-default(8);
    }
  }
}

.p-worksEntry__v {
  box-sizing: border-box;
  max-width: 1380px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 88px;
  position: relative;
  width: 100%;

  @include u-max-default() {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: sizeVW-default(70);
  }

  > img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.p-worksEntry__conSummary {
  margin-bottom: 50px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(64);
  }
}

.p-worksEntry__conSummary__title {
  @include u-font-size(28);
  font-weight: 700;
  line-height: (48 / 28);
  letter-spacing: 0.1em;
  margin-bottom: 30px;

  @include u-max-default() {
    font-size: sizeVW-default(36);
    line-height: (56 / 36);
    margin-bottom: sizeVW-default(26);
  }
}

.p-worksEntry__conSummary__text {
  > p {
    @include u-font-size(14);
    line-height: (38 / 14);
    letter-spacing: 0.14em;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
    }
  }

  > p + p {
    margin-top: 2em;
  }
}

.p-worksEntry__conAdd {
  display: flex;
  margin-bottom: 92px;

  @include u-max-default() {
    display: block;
    margin-bottom: sizeVW-default(76);
  }

  dl {
    dt {
      @include f-ITC-BoldIt();
      @include u-font-size(18);
      letter-spacing: 0.04em;
      margin-bottom: 12px;
      display: flex;
      align-items: center;

      @include u-max-default() {
        font-size: sizeVW-default(28);
        margin-bottom: sizeVW-default(18);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        background-color: #8c8c8c;
        content: '';
        display: block;
        height: 1px;
        width: 10px;
        margin-right: 4px;

        @include u-max-default() {
          height: sizeVW-default(2);
          width: sizeVW-default(20);
          margin-right: sizeVW-default(8);
        }
      }
    }
    dd {
      @include u-font-size(12);
      letter-spacing: 0.06em;
      line-height: (26 / 12);

      @include u-max-default() {
        font-size: sizeVW-default(22);
        line-height: (38 / 22);
      }

      a {
        position: relative;

        &:after {
          background-color: #939393;
          content: '';
          display: block;
          height: 1px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &:hover {
          &:after {
            opacity: 0;
          }
        }
      }
    }
  }

  dl + dl {
    margin-top: 38px;

    @include u-max-default() {
      margin-top: sizeVW-default(38);
    }
  }
}

.p-worksEntry__conAddLine {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:after {
    background-color: rgba(0, 0, 0, 0.8);
    content: '';
    display: block;
    height: 1px;
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;

    @include u-max-default() {
      width: sizeVW-default(140);
    }
  }
}

.p-worksEntry__conAdd__l {
  box-sizing: border-box;
  padding-top: 78px;
  width: 50%;
  position: relative;

  @include u-max-default() {
    padding-top: sizeVW-default(56);
    margin-bottom: sizeVW-default(50);
    width: 100%;
  }
}

.p-worksEntry__conAdd__r {
  box-sizing: border-box;
  padding-top: 78px;
  width: 50%;
  position: relative;

  @include u-max-default() {
    padding-top: sizeVW-default(56);
    width: 100%;
  }
}

.p-worksEntry__carousel {
  box-sizing: border-box;
  margin-bottom: 120px;

  @include u-max-default() {
    margin-bottom: 96px;
  }
}

.p-worksEntry__other {
  box-sizing: border-box;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}