.c-btnYoutube {
  box-sizing: border-box;
  border: solid 1px #5c5c5c;
  display: block;
  height: 32px;
  width: 32px;
  position: relative;

  @include u-max-default() {
    height: sizeVW-default(64);
    width: sizeVW-default(64);
  }

  &:before {
    background: url(../img/common/icon-yt-g.svg) no-repeat center center;
    background-size: 14px 10px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;

    @include u-max-default() {
      background-size: sizeVW-default(29) sizeVW-default(20);
    }
  }
}

.c-btnYoutube--white {
  border: solid 1px #fff;

  &:before {
    background: url(../img/common/icon-yt-w.svg) no-repeat center center;
    background-size: 14px 10px;

    @include u-max-default() {
      background-size: sizeVW-default(29) sizeVW-default(20);
    }
  }
}

.c-btnYoutube--black {
  border: solid 1px #727272;

  &:before {
    background: url(../img/common/icon-yt.svg) no-repeat center center;
    background-size: 14px 10px;

    @include u-max-default() {
      background-size: sizeVW-default(29) sizeVW-default(20);
    }
  }
}

.c-btnYoutube--noborder {
  border: 0;
}