.p-recruit {
  margin-bottom: 180px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(180);
  }
}

.p-recruit__title {
  text-align: center;
  height: 745px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include u-max-default() {
    height: sizeVW-default(858);
  }

  > h1 {
    @include u-font-size(98);
    font-weight: 700;
    letter-spacing: 0.06em;

    @include u-max-default() {
      font-size: sizeVW-default(100);
      letter-spacing: 0.12em;
    }
  }

  > p {
    @include u-font-size(14);
    line-height: (38 / 14);
    letter-spacing: 0.14em;
    margin-top: 36px;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
      letter-spacing: 0.1em;
      padding-left: sizeVW-default(70);
      padding-right: sizeVW-default(70);
      margin-top: sizeVW-default(36);
    }
  }
}

.p-recruit__con {
  box-sizing: border-box;
  max-width: 1680px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include u-max-default() {
    padding-left: 0;
    padding-right: 0;
  }
}

.p-recruit__con--new {
  margin-bottom: 120px;

  @include u-max-default() {
    margin-bottom: sizeVW-default(120);
  }

  .p-recruit__conWrap {
    padding-bottom: 120px;

    @include u-max-default() {
      padding-bottom: sizeVW-default(120);
    }
  }
}

.p-recruit__con--career {
  margin-top: sizeVW(-126, 1680);
  margin-bottom: 90px;

  @include u-max-default() {
    margin-top: 0;
    margin-bottom: sizeVW-default(80);
  }

  .p-recruit__conWrap {
    padding-bottom: 100px;

    @include u-max-default() {
      padding-bottom: sizeVW-default(120);
    }
  }
}

.p-recruit__conWrap {
  background-color: #fff;
  padding-top: 100px;
  padding-left: 150px;
  padding-right: 150px;

  @include u-max-default() {
    padding-top: sizeVW-default(100);
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}

.p-recruit__conWrap__title {
  display: flex;
  align-items: flex-end;

  h3 {
    @include u-font-size(28);
    font-weight: 700;
    letter-spacing: 0.16em;
    margin-left: 20px;

    @include u-max-default() {
      font-size: sizeVW-default(26);
      letter-spacing: 0.1em;
      margin-left: sizeVW-default(16);
    }
  }
}

.p-recruit__conWrap__lead {
  margin-top: 40px;

  @include u-max-default() {
    margin-top: sizeVW-default(40);
  }

  p {
    @include u-font-size(14);
    line-height: (38 / 14);
    letter-spacing: 0.12em;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      line-height: (48 / 24);
      letter-spacing: 0.1em;
    }
  }
}

.p-recruit__conWrap__exlink {
  border-bottom: solid 1px #000;
  margin-top: 86px;

  @include u-max-default() {
    margin-top: sizeVW-default(84);
  }

  li {
    border-top: solid 1px #000;
    height: 120px;
    width: 100%;

    @include u-max-default() {
      height: sizeVW-default(200);
    }

    a {
      @include u-font-size(24);
      font-weight: 700;
      letter-spacing: 0.02em;
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      @include u-max-default() {
        font-size: sizeVW-default(34);
        letter-spacing: 0.08em;
      }

      .icon-external {
        display: flex;
        align-items: center;

        &:after {
          background: url(../img/common/icon-external.svg) no-repeat;
          background-size: 100% 100%;
          content: '';
          display: inline-block;
          height: 13px;
          width: 13px;
          margin-left: 6px;

          @include u-max-default() {
            height: sizeVW-default(21);
            width: sizeVW-default(21);
            margin-left: sizeVW-default(8);
          }
        }
      }

      .icon-arrow {
        display: block;
        height: 16px;
        width: 16px;
        position: absolute;
        right: 4px;
        top: 50%;
        margin-top: -8px;

        @include u-max-default() {
          height: sizeVW-default(26);
          width: sizeVW-default(26);
          margin-top: sizeVW-default(-13);
          right: sizeVW-default(4);
        }

        &:before {
          box-sizing: border-box;
          border-right: solid 1px #000;
          border-top: solid 1px #000;
          content: '';
          display: block;
          height: 16px;
          width: 16px;
          transform: rotate(45deg);
          transform-origin: 50% 50%;

          @include u-max-default() {
            border-right: solid sizeVW-default(2) #000;
            border-top: solid sizeVW-default(2) #000;
            height: sizeVW-default(26);
            width: sizeVW-default(26);
          }
        }
      }
    }
  }
}

.p-recruit__conWrap__list {
  border-bottom: solid 1px #000;
  margin-top: 76px;

  @include u-max-default() {
    margin-top: sizeVW-default(88);
  }

  li {
    border-top: solid 1px #000;
  }
}

.p-recruit__toggle {
  cursor: pointer;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include u-max-default() {
    height: sizeVW-default(200);
  }
}

.p-recruit__toggleText {
  @include u-font-size(24);
  font-weight: 700;
  letter-spacing: 0.08em;

  @include u-max-default() {
    font-size: sizeVW-default(34);
    letter-spacing: 0.04em;
  }

  > span {
    @include u-font-size(14);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin-left: 6px;

    @include u-max-default() {
      font-size: sizeVW-default(24);
      display: block;
      margin-left: 0;
      margin-top: sizeVW-default(14);
    }
  }
}

.p-recruit__toggleCross {

}

.p-recruit__accord {
  overflow: hidden;
}

.p-recruit__accordWrap {
  padding-left: 60px;
  padding-right: 60px;

  @include u-max-default() {
    padding-left: sizeVW-default(2);
    padding-right: sizeVW-default(28);
  }

  h4 {
    @include u-font-size(14);
    font-weight: 700;
    font-style: italic;
    letter-spacing: 0.14em;
    margin-bottom: 40px;

    @include u-max-default() {
      font-size: sizeVW-default(22);
      letter-spacing: 0.04em;
      margin-bottom: sizeVW-default(60);
    }
  }

  dl {
    border-top: solid 1px #ccc;
    display: flex;
    align-items: center;
    padding-top: 34px;
    padding-bottom: 32px;

    @include u-max-default() {
      display: block;
      padding-top: sizeVW-default(56);
      padding-bottom: sizeVW-default(54);
    }

    dt {
      @include u-font-size(14);
      color: #666;
      font-weight: 700;
      font-style: italic;
      line-height: (28 / 14);
      letter-spacing: 0.14em;
      flex-basis: 258px;
      min-width: 258px;
      width: 258px;

      @include u-max-default() {
        font-size: sizeVW-default(22);
        line-height: (38 / 22);
        letter-spacing: 0;
        min-width: 100%;
        width: 100%;
        margin-bottom: sizeVW-default(12);
      }
    }

    dd {
      @include u-font-size(14);
      color: #666;
      line-height: (28 / 14);
      letter-spacing: 0.14em;

      @include u-max-default() {
        font-size: sizeVW-default(22);
        line-height: (39 / 22);
        letter-spacing: 0;
      }
    }
  }
}

.p-recruit__conWrap__require {
  margin-top: 100px;

  @include u-max-default() {
    margin-top: sizeVW-default(100);
  }

  h4 {
    @include u-font-size(14);
    font-weight: 700;
    font-style: italic;
    letter-spacing: 0.14em;
    margin-bottom: 38px;
  }

  dl {
    border-top: solid 1px #ccc;
    display: flex;
    align-items: center;
    padding-top: 34px;
    padding-bottom: 34px;

    @include u-max-default() {
      display: block;
      padding-top: sizeVW-default(56);
      padding-bottom: sizeVW-default(56);
    }

    &:last-child {
      border-bottom: solid 1px #ccc;
    }

    dt {
      @include u-font-size(14);
      color: #666;
      font-weight: 700;
      font-style: italic;
      line-height: (28 / 14);
      letter-spacing: 0.14em;
      flex-basis: 258px;
      min-width: 258px;
      width: 258px;

      @include u-max-default() {
        font-size: sizeVW-default(22);
        line-height: (38 / 22);
        letter-spacing: 0.04em;
        min-width: 100%;
        width: 100%;
      }
    }

    dd {
      @include u-font-size(14);
      color: #666;
      line-height: (28 / 14);
      letter-spacing: 0.14em;

      @include u-max-default() {
        font-size: sizeVW-default(22);
        line-height: (38 / 22);
        letter-spacing: 0;
      }
    }
  }
}

.p-recruit__v {
  width: 100%;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.p-recruit__other {
  box-sizing: border-box;
  max-width: 1680px;
  padding-left: 90px;
  padding-right: 90px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include u-max-default() {
    padding-left: sizeVW-default(60);
    padding-right: sizeVW-default(60);
  }
}