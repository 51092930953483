.c-gnav {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.c-gnav__trigger {
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;

  @include u-max-default() {
    height: sizeVW-default(100);
    width: sizeVW-default(100);
  }
}

.c-gnav__triggerCross {
  position: relative;
  height: 28px;
  width: 28px;
  transform: rotate(45deg);

  @include u-max-default() {
    height: sizeVW-default(38);
    width: sizeVW-default(38);
  }

  span {
    background-color: #000;
    display: block;
    position: absolute;

    &:nth-child(1) {
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;

      @include u-max-default() {
        height: sizeVW-default(2);
        margin-top: sizeVW-default(-1);
      }
    }

    &:nth-child(2) {
      height: 100%;
      width: 2px;
      top: 0;
      left: 50%;
      margin-left: -1px;

      @include u-max-default() {
        width: sizeVW-default(2);
        margin-left: sizeVW-default(-1);
      }
    }
  }
}

.c-gnavWrap {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;

  @include u-max-default() {
    display: block;
  }
}

.c-gnavWrap__side {
  background-color: #fff;
  box-sizing: border-box;
  height: 100%;
  width: 191px;
  position: relative;

  @include u-max-default() {
    height: auto;
    width: 100%;
  }
}

.c-gnavWrap__sideSns {
  background-color: #000;
  box-sizing: border-box;
  height: calc(100% - 70px);
  width: 70px;
  position: absolute;
  left: 0;
  top: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @include u-max-default() {
    position: relative;
    left: auto;
    top: auto;
    height: sizeVW-default(100);
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  ul {
    margin-bottom: 32px;

    @include u-max-default() {
      display: flex;
      margin-bottom: 0;
      margin-right: sizeVW-default(22);
    }

    li + li {
      margin-top: 10px;

      @include u-max-default() {
        margin-top: 0;
        margin-left: sizeVW-default(10);
      }
    }
  }
}

.c-gnavWrap__sideLogo {
  box-sizing: border-box;
  border-right: solid 1px #e5e5e5;
  height: 100%;
  width: 121px;
  position: absolute;
  top: 0;
  left: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include u-max-default() {
    position: relative;
    top: auto;
    left: auto;
    height: sizeVW-default(180);
    width: 100%;
  }

  img {
    height: 184px;
    width: 40px;

    @include u-max-default() {
      height: sizeVW-default(60);
      width: sizeVW-default(276);
    }
  }
}

.c-gnavWrap__nav {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  width: calc(100% - 191px);

  @include u-max-default() {
    display: block;
    height: auto;
    width: 100%;
  }
}

.c-gnavWrap__navPages {
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  height: 100%;
  width: 50%;

  @include u-max-default() {
    height: auto;
    width: 100%;
  }
}

.c-gnavWrap__navPages__wrap {
  box-sizing: border-box;
  padding-left: 40px;
  padding-top: 80px;

  @include u-max-default() {
    padding-left: sizeVW-default(50);
    padding-top: sizeVW-default(70);
  }

  &:nth-child(1) {
    padding-bottom: 76px;

    @include u-max-default() {
      border-top: solid 1px #e5e5e5;
      padding-bottom: sizeVW-default(48);
    }
  }

  &:nth-child(2) {
    border-top: solid 1px #e5e5e5;
    padding-bottom: 77px;

    @include u-max-default() {
      padding-bottom: sizeVW-default(48);
    }
  }

  &:nth-child(3) {
    border-top: solid 1px #e5e5e5;
    padding-bottom: 80px;

    @include u-max-default() {
      padding-bottom: sizeVW-default(70);
    }
  }

  &:nth-child(4) {
    border-top: solid 1px #e5e5e5;
    padding-bottom: 80px;

    @include u-max-default() {

    }
  }
}

.c-gnavWrap__navPages__wrapCaption {

}

.c-gnavWrap__navPages__wrapLinks1 {
  margin-top: 40px;

  @include u-max-default() {
    margin-top: sizeVW-default(18);
    display: flex;
    flex-wrap: wrap;
  }

  li {
    @include u-max-default() {
      margin-right: sizeVW-default(28);
      margin-bottom: sizeVW-default(18);
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      @include u-font-size(36);
      font-weight: 700;
      letter-spacing: 0.13em;

      @include u-max-default() {
        font-size: sizeVW-default(40);
        letter-spacing: 0.06em;
      }
    }
  }

  li + li {
    margin-top: 27px;

    @include u-max-default() {
      margin-top: 0;
    }
  }
}

.c-gnavWrap__navPages__wrapLinks2 {
  margin-top: 42px;

  @include u-max-default() {
    margin-top: sizeVW-default(18);
    display: flex;
    flex-wrap: wrap;
  }

  li {
    @include u-max-default() {
      margin-right: sizeVW-default(22);
      margin-bottom: sizeVW-default(18);
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      @include u-font-size(26);
      font-weight: 700;
      letter-spacing: 0.04em;

      @include u-max-default() {
        font-size: sizeVW-default(36);
        letter-spacing: 0.08em;
      }
    }
  }

  li + li {
    margin-top: 21px;

    @include u-max-default() {
      margin-top: 0;
    }
  }
}

.c-gnavWrap__navPages__wrapLinks3 {
  margin-top: 48px;

  @include u-max-default() {
    margin-top: sizeVW-default(28);
    display: flex;
    flex-wrap: wrap;
  }

  li {
    @include u-max-default() {
      margin-right: sizeVW-default(22);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  li + li {
    margin-top: 40px;

    @include u-max-default() {
      margin-top: 0;
    }
  }

  .icon-tech {
    @include u-max-default() {
      height: sizeVW-default(50);
      width: sizeVW-default(126);
    }
  }

  .icon-live {
    @include u-max-default() {
      height: sizeVW-default(50);
      width: sizeVW-default(126);
    }
  }
}

.c-gnavWrap__navPages__wrapLinks4 {
  margin-top: 28px;

  @include u-max-default() {
    margin-top: sizeVW-default(30);
  }

  li {
    a {
      @include u-font-size(18);
      font-weight: 700;
      letter-spacing: 0.13em;

      @include u-max-default() {
        font-size: sizeVW-default(26);
        line-height: (42 / 26);
        letter-spacing: 0.07em;
      }
    }
  }
}

.c-gnavWrap__navPages__wrapLang {
  ul {
    display: flex;

    li {
      box-sizing: border-box;
      border: solid 1px #000;
      height: 40px;
      width: 40px;

      @include u-max-default() {
        height: sizeVW-default(80);
        width: sizeVW-default(80);
      }

      &:nth-child(2) {
        margin-left: -1px;
      }

      a {
        @include u-font-size(18);
        font-weight: 700;
        letter-spacing: 0.13em;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include u-max-default() {
          font-size: sizeVW-default(30);
        }
      }
    }
  }
}

.c-gnavWrap__navPages__wrapAddress {
  margin-top: 12px;

  @include u-max-default() {
    margin-top: sizeVW-default(20);
  }

  p {
    @include u-font-size(12);
    font-weight: 700;
    letter-spacing: 0.14em;
    line-height: (24 / 12);

    @include u-max-default() {
      font-size: sizeVW-default(22);
      line-height: (38 / 22);
    }
  }
}

.c-gnavWrap__navTopics {
  box-sizing: border-box;
  background-color: #e9e9e9;
  position: relative;
  height: 100%;
  width: 50%;

  @include u-max-default() {
    height: auto;
    width: 100%;
  }
}

.c-gnavWrap__navTopics__wrap {
  padding-top: 80px;
  padding-bottom: 80px;
  width: (664 / 745) * 100%;
  margin-left: auto;
  margin-right: auto;

  @include u-max-default() {
    padding-top: sizeVW-default(80);
    padding-bottom: sizeVW-default(80);
    width: (630 / 750) * 100%;
  }
}

.c-gnavWrap__navTopics__wrapList {
  margin-top: 40px;

  @include u-max-default() {
    margin-top: sizeVW-default(26);
  }

  li {
    a {
      background-color: #fff;
      display: block;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 34px;
      padding-bottom: 30px;

      @include u-max-default() {
        padding-left: sizeVW-default(40);
        padding-right: sizeVW-default(40);
        padding-top: sizeVW-default(60);
        padding-bottom: sizeVW-default(54);
      }

      > div {
        display: flex;
        align-items: center;

        span {
          @include u-font-size(12);
          font-weight: 700;

          @include u-max-default() {
            font-size: sizeVW-default(20);
          }

          &:nth-child(2) {
            letter-spacing: 0.04em;
            margin-left: 13px;

            @include u-max-default() {
              letter-spacing: 0.06em;
              margin-left: sizeVW-default(13);
            }
          }
        }
      }

      > p {
        @include u-font-size(14);
        font-weight: 700;
        line-height: (24 / 12);
        margin-top: 5px;

        @include u-max-default() {
          font-size: sizeVW-default(24);
          line-height: (39 / 24);
          margin-top: sizeVW-default(18);
        }
      }
    }
  }

  li + li {
    margin-top: 30px;

    @include u-max-default() {
      margin-top: sizeVW-default(30);
    }
  }
}

.c-gnavWrap__navTopics__wrapMore {
  @include u-font-size(18);
  box-sizing: border-box;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.14em;
  height: 80px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include u-max-default() {
    font-size: sizeVW-default(28);
    height: sizeVW-default(90);
    width: sizeVW-default(300);
    margin-top: sizeVW-default(40);
  }
}