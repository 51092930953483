.c-navCaption {
  @include f-ITC-BoldIt();
  @include u-font-size(15);
  color: #999;
  letter-spacing: 0.11em;
  line-height: 1;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: -2px;

  @include u-max-default() {
    font-size: sizeVW-default(25);
    letter-spacing: 0.07em;
    margin-top: sizeVW-default(-2);
  }

  &:before {
    background-color: #999;
    content: '';
    display: block;
    height: 2px;
    width: 20px;
    margin-right: 10px;

    @include u-max-default() {
      height: sizeVW-default(4);
      width: sizeVW-default(26);
      margin-right: sizeVW-default(10);
    }
  }
}