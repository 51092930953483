.c-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.c-header__logo {
  position: absolute;
  top: 30px;
  right: 30px;
  height: 20px;
  width: 92px;

  @include u-max-default() {
    top: sizeVW-default(30);
    right: sizeVW-default(30);
    height: sizeVW-default(30);
    width: sizeVW-default(138);
  }

  svg {
    height: 100%;
    width: 100%;

    path {
      fill: #000;
    }
  }
}

.c-header__tool {
  position: absolute;
  top: 0;
  left: 0;
}

.c-header__trigger {
  cursor: pointer;
  position: relative;
  background-color: #fff;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include u-max-default() {
    height: sizeVW-default(100);
    width: sizeVW-default(100);
  }
}

.c-header__triggerWrap {
  position: relative;
  height: 19px;
  width: 20px;

  @include u-max-default() {
    height: sizeVW-default(30);
    width: sizeVW-default(30);
  }

  span {
    background-color: #000;
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;

    @include u-max-default() {
     height: sizeVW-default(2);
    }

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 9px;

      @include u-max-default() {
        top: sizeVW-default(14);
      }
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }
}

.c-header__bc {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  @include u-max-default() {
    display: none;
  }

  ul {

    li {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        @include u-font-size(14);
        font-weight: 700;
        letter-spacing: 0.14em;
        display: block;
        writing-mode: vertical-rl;
      }
    }

    li + li {
      &:before {
        background-color: #000;
        content: '';
        display: block;
        height: 60px;
        width: 1px;
        margin-top: 16px;
        margin-bottom: 20px;
      }
    }
  }
}