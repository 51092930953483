.c-btnMap {
  @include u-font-size(12);
  font-weight: 700;
  letter-spacing: 0.14em;
  margin-left: 11px;
  display: inline-flex;
  align-items: center;

  @include u-max-default() {
    font-size: sizeVW-default(22);
    margin-left: sizeVW-default(16);
  }

  &:after {
    background: url(../img/common/icon-map.svg) no-repeat center center;
    background-size: 100% 100%;
    content: '';
    display: inline-block;
    height: 13px;
    width: 10px;
    margin-left: 1px;

    @include u-max-default() {
      height: sizeVW-default(26);
      width: sizeVW-default(20);
      margin-left: sizeVW-default(4);
    }
  }

  span {
    position: relative;

    &:before {
      background-color: #b8b8b8;
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

.c-btnMap--b {
  &:after {
    background: url(../img/common/icon-map-b.svg) no-repeat center center;
    background-size: 100% 100%;
  }
}